
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import moment from "moment";
import ptBr from 'element-plus/lib/locale/lang/pt-br';
import { Modal } from "bootstrap";
import { addAgendamento, paralisaServico, addTempoAdicionalAgendamento, deletarAgendamento, finalizaServicoAgendado, cancelaServicoAgendado, iniciaServicoAgendado, atualizaPercentualRealizado, entregaRealizadaServicoAgendado, cancelaEntregaServicoAgendado } from "@/services/AgendaService";
import useAlert from "@/composables/Alert";
import { config } from "@/views/agenda-virtual/ConfigEvents";
import { useStore } from "vuex";

export default defineComponent({
  name: "new-event-modal",
  components: {},

  props: {
		eventInfo: Object,
    showStatusControl: {
      type: Boolean,
      default: false
    },
    showEntregaControl: {
      type: Boolean,
      default: false
    },
    editable: Boolean,
    id: String,
  },

  emits: ["addTask", "editTask", "success"],

  setup(props, { emit }) {
    const store = useStore();
    const { showConfirmAlert, showTimeAlert } = useAlert();

    const formRef = ref<null | HTMLFormElement>(null);
    const newTargetModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const showContent = ref<any>(true);
    const initialTaskEndHour = ref("");
    const initialHourEntrega = ref("");
    const loadingBtn = ref(false);

    const targetData:any = ref({
      codServico: "",
      codAgendamento: "",
      detalhes: "",
      cliente: "",
      placa: "",
      chassi: "",
      taskType: "",
      taskDate: "",
      taskStartHour: "",
      taskEndHour: "",
      taskHourEntrega: "",
      taskHourRecepcao: "",
      endWithout: "",
      formType: "",
      tecnico: "",
      dataAgendamento: "",
      marca: "",
      modelo: "",
      osAberta: "",
      tempoAdicional: "",
      tempoAdicionalSelected: "",
      codTipoServico: "",
    });
    const hidenButtonDms = [10,7] //7 = WorkFlow 10 = API-WorkFlow //
    const concessionaria = store.getters.concessionariaSelectedInfo
    const dms = ref<number>(concessionaria.dms)
    const aberturaOS = ref<number | string>(concessionaria.osdms)

    function closeModal() {
      const modal = document.getElementById(`kt_modal_event_info_${props.id}`);
      hideModal(modal);
    };

    function removeAcentos(name, toLowerCase = false) {
      if(!name){
        return "";
      }
      const stringTratado = name.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      return toLowerCase ? stringTratado.toLowerCase() : stringTratado;
    };

    watch(() => props.eventInfo, () => {
      const eventInfo:any = props.eventInfo;
      const eventConfig = config.events[eventInfo.codTipoServico - 1];
      let tecnicoInfo = mecanicos.value.find(tecnico => {
        const mecanico:any = tecnico;
        return mecanico.codMecanico == eventInfo.codTecnico;
      });

      let consultorAgendadoTratado = store.getters.consultoresList.find(consultor => {
        return removeAcentos(consultor.intConsultor, true) == removeAcentos(eventInfo.consultorAgendado, true)
      })?.nome;

      consultorAgendadoTratado = consultorAgendadoTratado ?? eventInfo.consultorAgendado;

      const info = {
        codServico: eventInfo.codServico,
        aguarda: eventInfo.aguarda,
        codAgendamento: eventInfo.codAgendamento,
        detalhes: eventInfo.observacao,
        cliente: eventInfo.cliente,
        placa: eventInfo.placa,
        chassi: eventInfo.chassi,
        taskType: eventConfig.titleExtended,
        codTipoServico: eventInfo.codTipoServico,
        taskDate: moment(eventInfo.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format(),
        taskDateTratada: moment(eventInfo.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY"),
        taskStartDate: moment(eventInfo.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),
        taskEndDate: moment(eventInfo.dataFinalServico, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),
        taskStartHour: moment(eventInfo.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm"),
        taskEndHour: moment(eventInfo.dataFinalServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm"),
        endWithout: moment(eventInfo.endWithout, "YYYY-MM-DD HH:mm:ss").format("HH:mm"),
        taskHourEntrega: moment(eventInfo.dataEntrega, "YYYY-MM-DD HH:mm:ss").format("HH:mm"),
        taskHourRecepcao: moment(eventInfo.dataRecepcao, "YYYY-MM-DD HH:mm:ss").format("HH:mm"),
        tecnico: tecnicoInfo?.nome ?? "",
        dataAgendamento: moment(eventInfo.dataAgendamento, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY"),
        consultorAgendadoTratado,
        consultorAgendado: eventInfo.consultorAgendado,
        marca: eventInfo.marca,
        modelo: eventInfo.modelo,
        osAberta: eventInfo.osAberta,
        percentualRealizado: eventInfo.percentualRealizado,
        tempoAdicional: eventInfo.tempoAdicional,
        tempoAdicionalSelected: eventInfo.tempoAdicional,
        status: eventInfo.status,
      };

      targetData.value = info; 
    });

    const tecnicoOptions:any = ref([]);
    const mecanicos:any = ref([]);

    onMounted(() => {
      if(store.getters.mecanicosList.length > 0){
        mecanicos.value = store.getters.mecanicosList;
      }
    });

    store.watch(() => store.getters.mecanicosList, () => {
      mecanicos.value = store.getters.mecanicosList;

      tecnicoOptions.value = store.getters.mecanicosList.map(tecnico => {
        const option =  {
          text: tecnico.nome,
          value: tecnico.codMecanico
        }

        return option;
      });
    }, { deep: true });

    watch(() => targetData.value.taskType, () => {
      if(targetData.value.taskType){
        const refOption = tipoOptions.find(option => option.value === targetData.value.taskType);
        showContent.value = refOption?.config.hasContent;
      } else {
        showContent.value = false;
      }
    });

    const tipoOptions = [
      {
        text: "TESTE",
        value: 1,
        config: {
          hasContent: true,
          hasFooter: true,
          bgColor: "#682E21",
          headerTextColor: "#ffffff"
        },
      },
      {
        text: "RET (Retorno)",
        value: 2,
        config: {
          hasContent: true,
          hasFooter: true,
          bgColor: "black",
          headerTextColor: "#ffffff"
        },
      },
      {
        text: "AL (Alinhamento)",
        value: 3,
        config: {
          hasContent: true,
          hasFooter: true,
          bgColor: "orange",
          headerTextColor: "#ffffff"
        },
      },
      {
        text: "DIAG (Diagnóstico)",
        value: 4,
        config: {
          hasContent: true,
          hasFooter: true,
          bgColor: "red",
          headerTextColor: "#ffffff"
        },
      },
      {
        text: "QS (Quick Service)",
        value: 5,
        config: {
          hasContent: true,
          hasFooter: true,
          bgColor: "#1F3059",
          headerTextColor: "#ffffff"
        },
      },
      {
        text: "MP (Manutenção Periódica)",
        value: 6,
        config: {
          hasContent: true,
          hasFooter: true,
          bgColor: "#darkgreen",
          headerTextColor: "#ffffff"
        },
      },
      {
        text: "RG (Reparo Geral)",
        value: 7,
        config: {
          hasContent: true,
          hasFooter: true,
          bgColor: "lawngreen",
          headerTextColor: "#000000"
        },
      },
      {
        text: "AC (Acessórios)",
        value: 8,
        config: {
          hasContent: true,
          hasFooter: true,
          bgColor: "yellow",
          headerTextColor: "#ffffff"
        },
      },
    ];

    const rules = ref({});


    const makeRange = (start: number, end: number) => {
      const result: number[] = []
      for (let i = start; i <= end; i++) {
        result.push(i)
      }
      return result
    }

    async function deletarEvento(){
      Swal.fire({
          title: "Deseja confirmar a exclusão do agendamento?",
          text: "A ação não poderá ser desfeita",
          position: 'center',
          showConfirmButton: true,
          reverseButtons: true,
          allowOutsideClick: false,
          showCancelButton: true,
          customClass: {
              confirmButton: "btn btn-flex background_btn_1_opct text-white",
              cancelButton: "btn btn-light me-3",
          },
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          focusConfirm: false,
        }).then((result) => {
          if (result.isConfirmed) {
            loadingBtn.value = true;
            const postData = {
              codServico: [targetData.value.codServico]
            };
            deletarAgendamento(postData)
            .then(response => {
              showTimeAlert("O agendamento foi deletado com sucesso!");
              emit("success");
              closeModal();
            })
            .catch(
              () => showTimeAlert("Não foi possível completar a solicitação", "error")
            ).finally(() => {
              loadingBtn.value = false;
            });
          }
        });
    }

    async function paralisarServico(){
      Swal.fire({
          title: "Deseja confirmar a paralização do serviço?",
          text: "A ação não poderá ser desfeita",
          position: 'center',
          showConfirmButton: true,
          reverseButtons: true,
          allowOutsideClick: false,
          showCancelButton: true,
          customClass: {
              confirmButton: "btn btn-flex background_btn_1_opct text-white",
              cancelButton: "btn btn-light me-3",
          },
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          focusConfirm: false,
        }).then((result) => {
          if (result.isConfirmed) {
            loadingBtn.value = true;
            paralisaServico(targetData.value.codServico)
            .then(response => {
              showTimeAlert("O serviço foi paralisado com sucesso!");
              emit("success");
              closeModal();
            })
            .catch(
              () => showTimeAlert("Não foi possível completar a solicitação", "error")
            ).finally(() => {
              loadingBtn.value = false;
            });
          }
        });
    }


    async function iniciarServico(){
      Swal.fire({
          title: "Deseja confirmar o início do serviço?",
          text: "A ação não poderá ser desfeita",
          position: 'center',
          showConfirmButton: true,
          reverseButtons: true,
          allowOutsideClick: false,
          showCancelButton: true,
          customClass: {
              confirmButton: "btn btn-flex background_btn_1_opct text-white",
              cancelButton: "btn btn-light me-3",
          },
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          focusConfirm: false,
        }).then((result) => {
          if (result.isConfirmed) {
            loadingBtn.value = true;
            iniciaServicoAgendado(targetData.value.codServico)
            .then(response => {
              showTimeAlert("O serviço foi iniciado com sucesso!");
              emit("success");
              closeModal();
            })
            .catch(
              () => showTimeAlert("Não foi possível completar a solicitação", "error")
            ).finally(() => {
              loadingBtn.value = false;
            });
          }
        });
    }

    async function entregaAoClienteRealizada(){
      Swal.fire({
          title: "Deseja confirmar a entrega do serviço?",
          text: "A ação indica que o carro foi entregue ao cliente",
          position: 'center',
          showConfirmButton: true,
          reverseButtons: true,
          allowOutsideClick: false,
          showCancelButton: true,
          customClass: {
              confirmButton: "btn btn-flex background_btn_1_opct text-white",
              cancelButton: "btn btn-light me-3",
          },
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          focusConfirm: false,
        }).then((result) => {
          if (result.isConfirmed) {
            loadingBtn.value = true;
            entregaRealizadaServicoAgendado(targetData.value.codServico)
            .then(response => {
              showTimeAlert("O serviço foi marcado como entregue");
              emit("success");
              closeModal();
            })
            .catch(
              () => showTimeAlert("Não foi possível completar a solicitação", "error")
            ).finally(() => {
              loadingBtn.value = false;
            });
          }
        });
    }

    async function cancelarEntregaAoClienteRealizada(){
      Swal.fire({
          title: "Deseja cancelar a entrega do serviço?",
          text: "A ação indica que o carro não foi entregue ao cliente",
          position: 'center',
          showConfirmButton: true,
          reverseButtons: true,
          allowOutsideClick: false,
          showCancelButton: true,
          customClass: {
              confirmButton: "btn btn-flex background_btn_1_opct text-white",
              cancelButton: "btn btn-light me-3",
          },
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          focusConfirm: false,
        }).then((result) => {
          if (result.isConfirmed) {
            loadingBtn.value = true;
            cancelaEntregaServicoAgendado(targetData.value.codServico)
            .then(response => {
              showTimeAlert("O serviço foi marcado como não entregue");
              emit("success");
              closeModal();
            })
            .catch(
              () => showTimeAlert("Não foi possível completar a solicitação", "error")
            ).finally(() => {
              loadingBtn.value = false;
            });
          }
        });
    }

    async function atualizaPercentual(percentual){
      Swal.fire({
          title: "Deseja confirmar a alteração do percentual realizado?",
          text: "A ação não poderá ser desfeita",
          position: 'center',
          showConfirmButton: true,
          reverseButtons: true,
          allowOutsideClick: false,
          showCancelButton: true,
          customClass: {
              confirmButton: "btn btn-flex background_btn_1_opct text-white",
              cancelButton: "btn btn-light me-3",
          },
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          focusConfirm: false,
        }).then((result) => {
          if (result.isConfirmed) {
            loadingBtn.value = true;
            atualizaPercentualRealizado(targetData.value.codServico, percentual)
            .then(response => {
              showTimeAlert("O percentual realizado foi atualizado com sucesso!");
              emit("success");
              closeModal();
            })
            .catch(
              () => showTimeAlert("Não foi possível completar a solicitação", "error")
            ).finally(() => {
              loadingBtn.value = false;
            });
          }
        });
    }

    async function cancelarServico(){
      Swal.fire({
          title: "Deseja voltar o serviço para o planejamento?",
          text: "A ação não poderá ser desfeita",
          position: 'center',
          showConfirmButton: true,
          reverseButtons: true,
          allowOutsideClick: false,
          showCancelButton: true,
          customClass: {
              confirmButton: "btn btn-flex background_btn_1_opct text-white",
              cancelButton: "btn btn-light me-3",
          },
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          focusConfirm: false,
        }).then((result) => {
          if (result.isConfirmed) {
              loadingBtn.value = true;
            cancelaServicoAgendado(targetData.value.codServico)
            .then(response => {
              showTimeAlert("O serviço voltou para o planejamento com sucesso!");
              emit("success");
              closeModal();
            })
            .catch(
              () => showTimeAlert("Não foi possível completar a solicitação", "error")
            ).finally(() => {
              loadingBtn.value = false;
            });
          }
        });
    }

    async function finalizarServico(){
      Swal.fire({
          title: "Deseja finalizar o serviço?",
          text: "A ação não poderá ser desfeita",
          position: 'center',
          showConfirmButton: true,
          reverseButtons: true,
          allowOutsideClick: false,
          showCancelButton: true,
          customClass: {
              confirmButton: "btn btn-flex background_btn_1_opct text-white",
              cancelButton: "btn btn-light me-3",
          },
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          focusConfirm: false,
        }).then((result) => {
          if (result.isConfirmed) {
            loadingBtn.value = true;
            finalizaServicoAgendado(targetData.value.codServico)
            .then(response => {
              showTimeAlert("O serviço foi finalizado com sucesso!");
              emit("success");
              closeModal();
            })
            .catch(
              () => showTimeAlert("Não foi possível completar a solicitação", "error")
            ).finally(() => {
              loadingBtn.value = false;
            });
          }
        });
    }

    async function addTempoAdicional(){
      const postData = {
        codServico: targetData.value.codServico,
        tempoAdicional: targetData.value.tempoAdicionalSelected
      };

      loadingBtn.value = true;
      const response = await addTempoAdicionalAgendamento(postData);
      loadingBtn.value = false;

      if(response.ocupado){
        let htmlOcupados = "<ul>";
        await response.horariosOcupados.forEach(horario => {
          const eventConfig = config.events[horario.codTipoServico - 1];
          const dataTratada = moment(targetData.value.taskDate).format("YYYY-MM-DD");
          const startTime = moment(horario.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
          const endTime = moment(horario.dataFinalServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");

          let startDate = "";
          let endDate = "";

          if (horario.codTipoServico == 10 || horario.codTipoServico == 11){
            startDate = moment(horario.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY");
            endDate = moment(horario.dataFinalServico, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY");
          } else {
            startDate = moment(`${dataTratada} ${startTime}`, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
            endDate = moment(`${dataTratada} ${endTime}`, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
          }

          htmlOcupados += `<li>${startDate} - ${endDate} (${eventConfig.title})</li>`
        });
        htmlOcupados += "</ul>";

        Swal.fire({
          title: response.mensagem,
          html: `<h4>Horário(s) ocupado(s):</h4> ${htmlOcupados}`,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok!",
          customClass: {
            confirmButton: "btn btn-flex background_btn_1_opct text-white",
          },
        });

        return;
      } else {
        showTimeAlert("O tempo adicional foi vinculado ao evento com sucesso!");
        closeModal();
        emit("success");
      }
    }

    async function confirmaTempoAdicional(){
        if(!props.editable){
          return;
        }

        let title = "";
        let text = "";
        let newTime = "";

        if(targetData.value.tempoAdicionalSelected == targetData.value.tempoAdicional) {
          return;
        } else if (targetData.value.tempoAdicional){
          newTime = moment(targetData.value.endWithout, "HH:mm").add(targetData.value.tempoAdicionalSelected, "minutes").format("HH:mm");
          title = "Deseja confirmar a alteração do tempo adicional?";
          text = `O horário final do serviço passará a ser ${newTime}`;
        } else {
          newTime = moment(targetData.value.taskEndHour, "HH:mm").add(targetData.value.tempoAdicionalSelected, "minutes").format("HH:mm");
          title = "Deseja confirmar a adição do tempo adicional?";
          text = `O horário final passará a ser ${newTime}`;
        }

        Swal.fire({
          title,
          text,
          position: 'center',
          showConfirmButton: true,
          reverseButtons: true,
          allowOutsideClick: false,
          showCancelButton: true,
          customClass: {
              confirmButton: "btn btn-flex background_btn_1_opct text-white",
              cancelButton: "btn btn-light me-3",
          },
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          focusConfirm: false,
        }).then((result) => {
          if (result.isConfirmed) {
            addTempoAdicional();
          } else {
            targetData.value.tempoAdicionalSelected = targetData.value.tempoAdicional;
          }
        });
    };

    function verificaHorarioInicial() {
      const startHour = moment(targetData.value.taskStartHour, "HH:mm");
      const endHour = moment(targetData.value.taskEndHour, "HH:mm");
      initialTaskEndHour.value = startHour.add(15, "minutes").format("HH:mm");

      if(endHour && endHour.isBefore(startHour)){
        targetData.value.taskEndHour = null;
      }
    }

    function verificaHorarioInicialRecepcao() {
      const startHour = moment(targetData.value.taskHourRecepcao, "HH:mm");
      const endHour = moment(targetData.value.taskHourEntrega, "HH:mm");
      initialHourEntrega.value = startHour.add(15, "minutes").format("HH:mm");

      if(endHour && endHour.isBefore(startHour)){
        targetData.value.taskHourEntrega = null;
      }
    }

    function readonlyHours() {
      return makeRange(0, 6).concat(makeRange(18, 23))
    }

    async function addNewTask(postData){
      try {
        const response = await addAgendamento(postData);

        if(response.ocupado){
          let htmlOcupados = "<ul>";
          await response.horariosOcupados.forEach(horario => {
            const eventConfig = config.events[horario.codTipoServico - 1];
            const dataTratada = moment(targetData.value.taskDate).format("YYYY-MM-DD");
            const startTime = moment(horario.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
            const endTime = moment(horario.dataFinalServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");

            let startDate = "";
            let endDate = "";

            if (horario.codTipoServico == 10 || horario.codTipoServico == 11){
              startDate = moment(horario.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY");
              endDate = moment(horario.dataFinalServico, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY");
            } else {
              startDate = moment(`${dataTratada} ${startTime}`, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
              endDate = moment(`${dataTratada} ${endTime}`, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
            }

            htmlOcupados += `<li>${startDate} - ${endDate} (${eventConfig.title})</li>`;
          });
          htmlOcupados += "</ul>";

          Swal.fire({
            title: response.mensagem,
            html: `<h4>Horário(s) ocupado(s):</h4> ${htmlOcupados}`,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok!",
            customClass: {
              confirmButton: "btn btn-flex background_btn_1_opct text-white",
            },
          });

          return;
        }

        Swal.fire({
          title: 'Agendamento realizado com sucesso!',
          text: 'Deseja agendar um outro serviço para o mesmo cliente?',
          position: 'center',
          showConfirmButton: true,
          reverseButtons: true,
          allowOutsideClick: false,
          showCancelButton: true,
          customClass: {
              confirmButton: "btn btn-flex background_btn_1_opct text-white",
              cancelButton: "btn btn-light me-3",
          },
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          focusConfirm: false,
        }).then((result) => {
          if (result.isConfirmed) {
              targetData.value.taskType = "";
              targetData.value.taskStartHour = "";
              targetData.value.taskEndHour = "";
              targetData.value.tecnico = "";
          } else {
            closeModal();
            emit("success");
          }
        });
      }catch(e){
        showTimeAlert("Não foi possível completar a solicitação", "error");
      } finally {
        loading.value = false;
      }
    }

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;

          const refOption = tipoOptions.find(option => option.value === targetData.value.taskType);
          const dataTratada = moment(targetData.value.taskDate).format("YYYY-MM-DD");

          if(targetData.value.formType == "New"){
            const postData = {
              cnpjConcessionaria: "20718525000174",
              codAgendamento: targetData.value.codAgendamento,
              codTipoServico: targetData.value.taskType,
              dataInicioServico: moment(dataTratada + " " + targetData.value.taskStartHour,).format("YYYY-MM-DD HH:mm:ss"),
              dataFinalServico: moment(dataTratada + " " + targetData.value.taskEndHour,).format("YYYY-MM-DD HH:mm:ss"),
              dataRecepcao: moment(dataTratada + " " + targetData.value.taskHourRecepcao).format("YYYY-MM-DD HH:mm:ss"),
              dataEntrega: moment(dataTratada + " " + targetData.value.taskHourEntrega).format("YYYY-MM-DD HH:mm:ss"),
              codTecnico: targetData.value.tecnico,
              cliente: targetData.value.cliente,
              placa: targetData.value.placa,
              dataAgendamento: moment(targetData.value.dataAgendamento, "DD/mm/YY HH:mm").format("YYYY-MM-DD HH:mm:ss"),
              marca: targetData.value.marca,
              modelo: targetData.value.modelo,
              consultorAgendado: targetData.value.consultorAgendado,
              osAberta: targetData.value.osAberta,
              observacao: targetData.value.detalhes,
            }

            addNewTask(postData);

            emit("addTask", postData);
          } else if(targetData.value.formType == "Edit"){
            const postData = {
              id: targetData.value.codAgendamento,
              title: targetData.value.taskType,
              start:  moment(dataTratada + " " + targetData.value.taskStartHour).format(),
              end: moment(dataTratada + " " + targetData.value.taskEndHour).format(),
              resourceId: targetData.value.tecnico,
              extendedProps: {
                cliente: targetData.value.cliente,
                recepcao: moment(dataTratada + " " + targetData.value.taskHourRecepcao).format(),
                entrega: moment(dataTratada + " " + targetData.value.taskHourEntrega).format(),
                placa: targetData.value.placa,
                hasContent: refOption?.config.hasContent,
                hasFooter: refOption?.config.hasFooter,
                bgColor: refOption?.config.bgColor,
                headerTextColor: refOption?.config.headerTextColor
              }
            }
            emit("editTask", postData);
          }
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-flex background_btn_1_opct text-white",
            },
          });
          return false;
        }
      });
    };

    return {
      tecnicoOptions,
      formRef,
      newTargetModalRef,
      loading,
      targetData,
      showContent,
      rules,
      submit,
      readonlyHours,
      initialTaskEndHour,
      initialHourEntrega,
      verificaHorarioInicial,
      verificaHorarioInicialRecepcao,
      tipoOptions,
      ptBr,
      closeModal,
      confirmaTempoAdicional,
      deletarEvento,
      finalizarServico,
      cancelarServico,
      iniciarServico,
      loadingBtn,
      atualizaPercentual,
      cancelarEntregaAoClienteRealizada,
      entregaAoClienteRealizada,
      paralisarServico,
      modeloAgendaName: computed(() => store.getters.modeloAgendaName),
      dms,
      aberturaOS,
      hidenButtonDms
    };
  },
});
