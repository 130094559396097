
import { defineComponent, onMounted, ref, watch,reactive } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import moment from "moment";
import ptBr from 'element-plus/lib/locale/lang/pt-br';
import { addAgendamento, getServicosAgendadosFiltrados, getHorariosOcupadosMecanicos,consultaOsAgenda } from "@/services/AgendaService";
import useAlert from "@/composables/Alert";
import { config } from "@/views/agenda-virtual/ConfigEvents";
import { useStore } from "vuex";
import {ServicoSolicitado} from "@/models/AgendaModel"

export default defineComponent({
  name: "new-passante-modal",
  components: {},
    props: {
      id: String,
      initialForm: Object,
    },
  emits: ["addTask", "editTask", "success"],
  setup(props, { emit }) {
    const { showConfirmAlert, showTimeAlert } = useAlert();
    const store = useStore();
    const formRef = ref<null | HTMLFormElement>(null);
    const newTargetModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const loadingOs = ref<boolean>(false);
    const showContent = ref<any>(true);
    const initialTaskEndHour = ref("");
    const initialHourEntrega = ref("");
    const modeloName = ref(store.getters.modeloAgendaName);
    const targetData:any = ref({
      codAgendamento: "",
      detalhes: "",
      cliente: "",
      placa: "",
      taskType: "",
      taskDate: "",
      taskEndDate: "",
      taskStartHour: "",
      taskEndHour: "",
      taskHourEntrega: "",
      taskHourRecepcao: "",
      formType: "",
      tecnico: "",
      consultorAgendado: "",
      dataAgendamento: "",
      marca: "",
      modelo: "",
      osAberta: "",
    });
    const horarios = reactive({
      horaAbertura: '08:00',
      horaFechamento: '19:00',
    })
    const defaultInitialTimes:any = ref(geraDefaultTimes(horarios.horaAbertura, horarios.horaFechamento));
    const defaultFinalTimes:any = ref([]);
    const tecnicoOptions:any = ref([]);
    const consultorOptions:any = ref([]);
    const horariosOcupados:any = ref([]);
    const servicosAgendado = ref<ServicoSolicitado[] | []>([])
    const tipoOptions = [
      {
        text: "TESTE",
        value: 1,
        config: {
          hasContent: true,
          hasFooter: true,
          bgColor: "#682E21",
          headerTextColor: "#ffffff"
        },
      },
      {
        text: "RET (Retorno)",
        value: 2,
        config: {
          hasContent: true,
          hasFooter: true,
          bgColor: "black",
          headerTextColor: "#ffffff"
        },
      },
      {
        text: "AL (Alinhamento)",
        value: 3,
        config: {
          hasContent: true,
          hasFooter: true,
          bgColor: "orange",
          headerTextColor: "#ffffff"
        },
      },
      {
        text: "DIAG (Diagnóstico)",
        value: 4,
        config: {
          hasContent: true,
          hasFooter: true,
          bgColor: "red",
          headerTextColor: "#ffffff"
        },
      },
      {
        text: "QS (Quick Service)",
        value: 5,
        config: {
          hasContent: true,
          hasFooter: true,
          bgColor: "#1F3059",
          headerTextColor: "#ffffff"
        },
      },
      {
        text: "MP (Manutenção Periódica)",
        value: 6,
        config: {
          hasContent: true,
          hasFooter: true,
          bgColor: "#darkgreen",
          headerTextColor: "#ffffff"
        },
      },
      {
        text: "RG (Reparo Geral)",
        value: 7,
        config: {
          hasContent: true,
          hasFooter: true,
          bgColor: "lawngreen",
          headerTextColor: "#000000"
        },
      },
      {
        text: "AC (Acessórios)",
        value: 8,
        config: {
          hasContent: true,
          hasFooter: true,
          bgColor: "yellow",
          headerTextColor: "#ffffff"
        },
      },
    ];
    const rules = ref({
      consultorAgendado: [
        {
          required: true,
          message: "Selecione o consultor responsável pelo serviço",
          trigger: "blur",
        },
      ],
      // cliente: [
      //   {
      //     required: true,
      //     message: "Digite o nome do cliente",
      //     trigger: "blur",
      //   },
      // ],
      placa: [
        {
          validator: (rule: any, value: any, callback: any) => {
            if (value === '' && targetData.value.chassi === '') {
              callback(new Error('Digite a placa do carro ou chassi'))
            } else {
              callback()
            }
          },
          message: "Digite a placa do carro ou chassi",
          trigger: "blur",
        },
      ],
      modelo: [
        {
          required: true,
          message: "Digite o modelo do carro",
          trigger: "blur",
        },
      ],
      tecnico: [
        {
          required: true,
          message: "Selecione o técnico responsável pelo serviço",
          trigger: "blur",
        },
      ],
      taskType: [
        {
          required: true,
          message: "Selecione o tipo de agendamento",
          trigger: "blur",
        },
      ],
      taskDate: [
        {
          required: true,
          message: "Selecione a data do agendamento",
          trigger: "blur",
        },
      ],
      taskStartHour: [
        {
          required: true,
          message: "Selecione o horário inicial de realização do serviço",
          trigger: "blur",
        },
      ],
      taskEndHour: [
        {
          required: true,
          message: "Selecione o horário final de realização do serviço",
          trigger: "blur",
        },
      ],
      taskHourEntrega: [
        { 
          required: true,
          message: "Selecione o horário de recepção do cliente",
          trigger: "blur"
        }
      ]
      // taskHourRecepcao: [
      //   {
      //     required: true,
      //     message: "Selecione o horário de recepção do cliente",
      //     trigger: "blur",
      //   },
      // ],
    });
    const usaChecklist = ref('')
    const horariosOcupadosDias: any = ref([])
    function horariosQuadro(){
      const concessionaria = store.getters.concessionariaSelectedInfo
      usaChecklist.value = concessionaria.usaChecklist
      if(!(Object.keys(concessionaria).length === 0)){
        horarios.horaAbertura =  moment(concessionaria.horaAbertura, "H").format("HH:mm") 
        horarios.horaFechamento =  moment(concessionaria.horaFechamento, "H").format("HH:mm") 
        defaultInitialTimes.value =  geraDefaultTimes(horarios.horaAbertura, horarios.horaFechamento)
      }      
    }

    // WATCH HOOKS
    watch(() => props.initialForm, async () => {
      loading.value = false
      servicosAgendado.value = [];

      const defaultForm = {
        codAgendamento: "",
        detalhes: "",
        cliente: "",
        placa: "",
        taskType: "",
        taskDate: "",
        taskStartHour: "",
        taskEndDate: "",
        taskEndHour: "",
        taskHourEntrega: "",
        taskHourRecepcao: "",
        formType: "",
        tecnico: "",
        consultorAgendado: "",
        dataAgendamento: "",
        marca: "",
        modelo: "",
        osAberta: "",
      };
      
      targetData.value = { ...defaultForm, ...props.initialForm };

      if(props.initialForm?.osAberta){
        if(props.initialForm.servicoAgendado){
          servicosAgendado.value = Array.isArray(props.initialForm.servicoAgendado['Servico']) ? props.initialForm.servicoAgendado['Servico'] : [props.initialForm.servicoAgendado['Servico']];
        }        
      }

      targetData.value.taskEndDate = props.initialForm?.taskDate

      verificaHorariosOcupados();
      verificaHorarioInicialRecepcao();
      verificaHorarioInicial();
    },{deep: true});

    onMounted(() => {
       horariosQuadro()
      if(store.getters.mecanicosList.length > 0){
        const mecanicos:any = store.getters.mecanicosList;
        
        tecnicoOptions.value = mecanicos.map(tecnico => {
          const option =  {
            text: tecnico.nome,
            value: tecnico.codMecanico
          }

          return option;
        });
      }

      if(store.getters.consultoresList.length > 0){
        const consultores:any = store.getters.consultoresList;
        let auxConsultores = consultores.map(consultor => {
          const option =  {
            text: consultor.nome,
            value: consultor.intConsultor ?? removeAcentos(consultor.nome).toUpperCase()
          }

          return option;
        });

        auxConsultores.push({
          text: "Outros funcionários",
          value: "OUTROS FUNCIONARIOS",
        });

        consultorOptions.value = [...auxConsultores];
      }
    });

    store.watch(()=>store.getters.concessionariasList,()=> {
        if(store.getters.concessionariasList) horariosQuadro()        
      })

    watch(() => targetData.value.taskType, () => {
      if(targetData.value.taskType){
        const refOption = tipoOptions.find(option => option.value === targetData.value.taskType);
        showContent.value = refOption?.config.hasContent;
      } else {
        showContent.value = false;
      }
    });

    store.watch(() => store.getters.consultoresList, () => {
      const consultores:any = store.getters.consultoresList;
      let auxConsultores = consultores.map(consultor => {
        const option =  {
          text: consultor.nome,
          value: consultor.intConsultor ?? removeAcentos(consultor.nome).toUpperCase()
        }

        return option;
      });

      auxConsultores.push({
        text: "Outros funcionários",
        value: "OUTROS FUNCIONARIOS",
      });

      consultorOptions.value = [...auxConsultores];
    }, { deep: true });

    store.watch(() => store.getters.mecanicosList, () => {
      const mecanicos:any = store.getters.mecanicosList;

      tecnicoOptions.value = mecanicos.map(tecnico => {
        const option =  {
          text: tecnico.nome,
          value: tecnico.codMecanico
        }

        return option;
      });
    }, { deep: true });

    store.watch(() => store.getters.modeloAgendaName, () => {
      modeloName.value = store.getters.modeloAgendaName;
    });

    // FUNCTIONS
    function geraDefaultTimes(minTime = "07:00", maxTime = "19:00", step = 15, showLastTime = false){
      const refTrueLastTime = moment(maxTime, "HH:mm");
      let refLastTime = refTrueLastTime.clone();
      if(!showLastTime){
        refLastTime.subtract(step, "minutes");
      }

      let auxDefaultTimes:any = [];
      let auxTime = moment(minTime, "HH:mm");
      auxTime.subtract(step, "minutes");

      while(auxTime.clone().isBefore(refLastTime)) {
        auxTime.add(step, "minutes");
        const nextTime = auxTime.clone();

        if(nextTime.clone().isBefore(refTrueLastTime) || (showLastTime && nextTime.isSame(refTrueLastTime))){
          auxDefaultTimes.push({
            text: nextTime.format("HH:mm"),
            value: nextTime.format("HH:mm"),
            disabled: false
          });
        }
      }

      return auxDefaultTimes;
    }

    function closeModal() {
      const modal = document.getElementById(`kt_modal_add_without_ref_event_${props.id}`);
      hideModal(modal);
    };

    function removeAcentos(name, toLowerCase = false) {
      if(!name){
        return "";
      }
      const stringTratado = name.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      return toLowerCase ? stringTratado.toLowerCase() : stringTratado;
    };

    async function getHorariosOcupados(codConcessionaria, dataFormatada) {
      return await getHorariosOcupadosMecanicos(codConcessionaria, dataFormatada);
    }

    async function verificaHorariosOcupados(){
      const dataInicial = moment(targetData.value.taskDate); // Use taskDate para a data inicial
      const dataFinal = moment(targetData.value.taskEndDate);

      // Comparar apenas o dia, mês e ano, ignorando a hora, minuto e segundo
      const saoIguais = dataInicial.isSame(dataFinal, 'day');

      const dataInicialFormatada = dataInicial.format("YYYY-MM-DD");
      const dataFinalFormatada = dataFinal.format("YYYY-MM-DD");

      if (saoIguais || usaChecklist.value != '147') {
        try {
          const response = await getHorariosOcupadosMecanicos(targetData.value.codConcessionaria, dataInicialFormatada);
          horariosOcupados.value = response;
        } catch (error) {
          showTimeAlert("Não foi possível completar a solicitação", "error");
          closeModal()
        } 
      } else {
        try {
          const [responseDataInicial, responseDataFinal] = await Promise.all([
            getHorariosOcupados(targetData.value.codConcessionaria, dataInicialFormatada),
            getHorariosOcupados(targetData.value.codConcessionaria, dataFinalFormatada)
          ]);

          horariosOcupadosDias.value = responseDataFinal
          horariosOcupados.value = responseDataInicial;       
        } catch (error) {
          showTimeAlert("Não foi possível completar a solicitação", "error");
          closeModal()
        }
      }
     

      desabilitaHorariosOcupados();
    };

    async function desabilitaHorariosOcupados(){
      defaultInitialTimes.value = geraDefaultTimes(horarios.horaAbertura, horarios.horaFechamento);
      verificaHorarioInicial();

      const dataInicial = moment(targetData.value.taskDate); // Use taskDate para a data inicial
      const dataFinal = moment(targetData.value.taskEndDate);

      //caso a data inicial for maior que a data final
      if (dataInicial.isAfter(dataFinal)){
        targetData.value.taskEndDate = targetData.value.taskDate
      }      

      // Comparar apenas o dia, mês e ano, ignorando a hora, minuto e segundo
      const dataInicialEFinalIgual = dataInicial.isSame(dataFinal, 'day');
        

      let auxHorarioFinalOcupado = false;
      if(targetData.value.tecnico){
        const horariosOcupadosMecanicoSelecionadoDataFinal = horariosOcupadosDias.value.filter(horario => horario.codTecnico == targetData.value.tecnico);
        const horariosOcupadosMecanicoSelecionadoDataInicial = horariosOcupados.value.filter(horario => horario.codTecnico == targetData.value.tecnico);

        const dataComparacao = moment(targetData.value.taskEndDate).startOf('day'); 

        const todasIguais = horariosOcupadosMecanicoSelecionadoDataInicial.every(item => {
            const dataFinalServico = moment(item.dataFinalServico).startOf('day');
            return dataFinalServico.isSame(dataComparacao);
        });
        if(dataInicialEFinalIgual || usaChecklist.value != '147'){
         
          horariosOcupadosMecanicoSelecionadoDataInicial.forEach(horario => {
            let auxStartTime:any = moment(horario.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
            auxStartTime = moment(auxStartTime, "HH:mm");

            let auxEndTime:any =  moment(horario.dataFinalServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
            auxEndTime = moment(auxEndTime, "HH:mm");
                  
            const auxDefaultInitialTimes = defaultInitialTimes.value.map(time => {
              if(horario.codTipoServico == 10 || horario.codTipoServico == 11 || moment(time.text, "HH:mm").isBetween(auxStartTime, auxEndTime, undefined, "[)")){
                return {
                  ...time,
                  disabled: true
                };
              } else {
                return time;
              }
            })
            defaultInitialTimes.value = auxDefaultInitialTimes;

              if(!auxHorarioFinalOcupado){  
                  defaultFinalTimes.value = defaultFinalTimes.value.map(time => {
                      if(auxHorarioFinalOcupado || horario.codTipoServico == 10 || horario.codTipoServico == 11 || moment(time.text, "HH:mm").isBetween(auxStartTime, auxEndTime, undefined, "(]")){
                        auxHorarioFinalOcupado = true;
                      
                        return {
                          ...time,
                          disabled: true
                        };
                      } else {
                        return time;
                      }
                    }).filter(time => !time.disabled);
              }    
              
              if(todasIguais){
                  if(horario.codTipoServico == 10 || horario.codTipoServico == 11 || moment(targetData.value.taskStartHour, "HH:mm").isBetween(auxStartTime, auxEndTime, undefined, "[)")) {
                  targetData.value.taskStartHour = "";
                  targetData.value.taskEndHour = "";
                } else if(
                  moment(targetData.value.taskEndHour, "HH:mm").isBetween(auxStartTime, auxEndTime, undefined, "(]") ||
                  (auxEndTime.isBetween(moment(targetData.value.taskStartHour, "HH:mm"), moment(targetData.value.taskEndHour, "HH:mm"), undefined, "(]") &&
                    auxStartTime.isBetween(moment(targetData.value.taskStartHour, "HH:mm"), moment(targetData.value.taskEndHour, "HH:mm"), undefined, "[)")
                  )
                ) {
                  targetData.value.taskEndHour = "";
                }
              } 
          })
          
        }else{

          let lastBlockedEndTime : any = null;

          horariosOcupadosMecanicoSelecionadoDataInicial.forEach(horario => {
              let auxStartTime2 : any = moment(horario.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
              auxStartTime2 = moment(auxStartTime2, "HH:mm");

              let auxEndTime2 : any = moment(horario.dataFinalServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
              auxEndTime2 = moment(auxEndTime2, "HH:mm");

              //aqui pegamos os ultimo horario ocupado para bloquear os anteriores
              defaultInitialTimes.value = defaultInitialTimes.value.map((time, index) => {
                if (horario.codTipoServico == 10 || horario.codTipoServico == 11 || moment(time.text, "HH:mm").isBetween(auxStartTime2, auxEndTime2, undefined, "[)")) {
                  lastBlockedEndTime = lastBlockedEndTime ? moment.max(lastBlockedEndTime, auxEndTime2) : auxEndTime2;
                  return {
                    ...time,
                    disabled: true
                  };
                } else {
                  return time;
                }
              });
                  
          })

          //logica para pega o ultimo horario ocupado e bloquear todos antes dele 
          if (lastBlockedEndTime) {
            defaultInitialTimes.value = defaultInitialTimes.value.map((time) => {
              if (moment(time.text, "HH:mm").isSameOrBefore(lastBlockedEndTime, 'minute')) {
                return {
                  ...time,
                  disabled: true
                };
              } else {
                return time;
              }
            });
          }

          const novasHoras = geraDefaultTimes(horarios.horaAbertura, horarios.horaFechamento);
          let isFirstIteration = true;
          defaultFinalTimes.value = geraDefaultTimes(horarios.horaAbertura, horarios.horaFechamento);

          horariosOcupadosMecanicoSelecionadoDataFinal.forEach(horario => {
              let auxStartTime2: any = moment(horario.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
              auxStartTime2 = moment(auxStartTime2, "HH:mm");

              let auxEndTime2: any = moment(horario.dataFinalServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
              auxEndTime2 = moment(auxEndTime2, "HH:mm");

              // todos horarios depois do primeiro horario ocupado e bloqueado
              defaultFinalTimes.value = novasHoras.map(time => {
                  if (
                      horario.codTipoServico == 10 ||
                      horario.codTipoServico == 11 ||
                      (moment(time.text, "HH:mm").isBetween(auxStartTime2, auxEndTime2, undefined, "(]") && isFirstIteration)
                  ) {
                      isFirstIteration = false;
                      return {
                          ...time,
                          disabled: true,
                      };
                  } else if (!isFirstIteration) {
                      return {
                          ...time,
                          disabled: true,
                      };
                  } else {
                      return time;
                  }
              });
          });          
        } 
      }
      // if(targetData.value.tecnico){
      //   const horariosOcupadosMecanicoSelecionado = horariosOcupados.value.filter(horario => horario.codTecnico == targetData.value.tecnico);

      //   const dataComparacao = moment(targetData.value.taskEndDate).startOf('day'); // Inicia a data no início do dia

      //   const todasIguais = horariosOcupadosMecanicoSelecionado.every(item => {
      //       const dataFinalServico = moment(item.dataFinalServico).startOf('day');
      //       return dataFinalServico.isSame(dataComparacao);
      //   });
        
      //   horariosOcupadosMecanicoSelecionado.forEach(horario => {
      //     let auxStartTime:any = moment(horario.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
      //     auxStartTime = moment(auxStartTime, "HH:mm");

      //     let auxEndTime:any =  moment(horario.dataFinalServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
      //     auxEndTime = moment(auxEndTime, "HH:mm");

      //     if(usaChecklist.value == '147'){
      //       const auxStartTime = moment(horario.dataInicioServico, "YYYY-MM-DD HH:mm:ss");
      //       const auxEndTime = moment(horario.dataFinalServico, "YYYY-MM-DD HH:mm:ss");

      //       const auxDefaultInitialTimes = defaultInitialTimes.value.map(time => {
      //         // Verificar se o dia da semana é o mesmo do término do serviço
      //         const isSameWeekday = moment(time.text, "HH:mm").isoWeekday() === auxEndTime.isoWeekday();

      //         // Verificar se o horário está no mesmo dia do término do serviço
      //         const isSameDay = moment(time.text, "HH:mm").isSame(auxEndTime, 'day');

      //         // Verificar se o horário está entre o início e o término do serviço
      //         const isBetweenTimes = moment(time.text, "HH:mm").isBetween(auxStartTime, auxEndTime, undefined, "[)");

      //         if (horario.codTipoServico == 10 || horario.codTipoServico == 11 || (isSameWeekday && isSameDay && isBetweenTimes)) {
      //           return {
      //             ...time,
      //             disabled: true
      //           };
      //         } else {
      //           return time;
      //         }
      //       });

      //       defaultInitialTimes.value = auxDefaultInitialTimes;
      //     }else{
      //       const auxDefaultInitialTimes = defaultInitialTimes.value.map(time => {
      //         if(horario.codTipoServico == 10 || horario.codTipoServico == 11 || moment(time.text, "HH:mm").isBetween(auxStartTime, auxEndTime, undefined, "[)")){
      //           return {
      //             ...time,
      //             disabled: true
      //           };
      //         } else {
      //           return time;
      //         }
      //       })
      //       defaultInitialTimes.value = auxDefaultInitialTimes;
      //     }          

      //     if(!auxHorarioFinalOcupado){  
      //       if(usaChecklist.value == '147'){
      //         if(usaChecklist.value == '147' && saoIguais){ 
                 

      //         }else{
      //           if(todasIguais){

                   
      //           }else{
      //             defaultFinalTimes.value =  defaultInitialTimes.value.map(time => {
      //               return {
      //                   ...time,
      //                   disabled: false
      //                 };
      //             })
      //           }               
      //         }
      //       }else{
      //         defaultFinalTimes.value = defaultFinalTimes.value.map(time => {
      //             if(auxHorarioFinalOcupado || horario.codTipoServico == 10 || horario.codTipoServico == 11 || moment(time.text, "HH:mm").isBetween(auxStartTime, auxEndTime, undefined, "(]")){
      //               auxHorarioFinalOcupado = true;
                  
      //               return {
      //                 ...time,
      //                 disabled: true
      //               };
      //             } else {
      //               return time;
      //             }
      //           }).filter(time => !time.disabled);
      //       }            
      //     }          
          
      //     if(todasIguais){
      //         if(horario.codTipoServico == 10 || horario.codTipoServico == 11 || moment(targetData.value.taskStartHour, "HH:mm").isBetween(auxStartTime, auxEndTime, undefined, "[)")) {
      //         targetData.value.taskStartHour = "";
      //         targetData.value.taskEndHour = "";
      //       } else if(
      //         moment(targetData.value.taskEndHour, "HH:mm").isBetween(auxStartTime, auxEndTime, undefined, "(]") ||
      //         (auxEndTime.isBetween(moment(targetData.value.taskStartHour, "HH:mm"), moment(targetData.value.taskEndHour, "HH:mm"), undefined, "(]") &&
      //           auxStartTime.isBetween(moment(targetData.value.taskStartHour, "HH:mm"), moment(targetData.value.taskEndHour, "HH:mm"), undefined, "[)")
      //         )
      //       ) {
      //         targetData.value.taskEndHour = "";
      //       }
      //     } 
      //   })

      // }
    }

    function verificaHorarioInicial() {
      const startHour = moment(targetData.value.taskStartHour, "HH:mm");
      const auxStartHour = startHour.clone();
      const endHour = moment(targetData.value.taskEndHour, "HH:mm");
     
      defaultFinalTimes.value = geraDefaultTimes(auxStartHour.clone().add(15, "minutes").format("HH:mm"), horarios.horaFechamento, 15, true);

      if(usaChecklist.value !== '147'){
        if(endHour && endHour.isBefore(startHour)){
         targetData.value.taskEndHour = null;
        }
      }  
    }

    function verificaHorarioInicialRecepcao() {
      const startHour = moment(targetData.value.taskHourRecepcao, "HH:mm");
      const auxStartHour = startHour.clone();
      const endHour = moment(targetData.value.taskHourEntrega, "HH:mm");
      initialHourEntrega.value = auxStartHour.clone().add(15, "minutes").format("HH:mm");

      if(endHour && endHour.isBefore(startHour)){
        targetData.value.taskHourEntrega = null;
      }
    }

    async function searchOs() {
      if(!loadingOs.value && targetData.value.osAberta){
        loadingOs.value = true;
        const postData = {
          numeroOs: targetData.value.osAberta,
          codConcessionaria: targetData.value.codConcessionaria
        }
        
        const response = await consultaOsAgenda(postData).catch(() => showTimeAlert("Algo deu Errado", "error"));

        if(response){
          if(response.osEncontrada){
              Swal.fire({
              title: 'Foi encontrado um agendamento com a mesma OS!',
              text: 'Deseja preencher os dados com base nessa OS?',
              position: 'center',
              showConfirmButton: true,
              reverseButtons: true,
              allowOutsideClick: false,
              showCancelButton: true,
              customClass: {
                  confirmButton: "btn btn-flex background_btn_1_opct text-white",
                  cancelButton: "btn btn-light me-3",
              },
              confirmButtonText: "Sim",
              cancelButtonText: "Não",
              focusConfirm: false,
            }).then((result) => {
              if (result.isConfirmed) { 
                targetData.value.cliente = response.cliente ?? '';
                targetData.value.modelo = response.modelo ?? '';
                targetData.value.placa = response.placa ?? '';
                targetData.value.chassi = response.chassi ?? '';
                targetData.value.tecnico = response.codMecanico ?? "";
                targetData.value.osAberta = response.numeroOS ?? targetData.value.osAberta
                servicosAgendado.value = Array.isArray(response.servicos['Servico']) ? response.servicos['Servico'] : [response.servicos['Servico']];
              }
            });
          }else {
            showTimeAlert("Nenhum serviço encontrado com essa OS", "error");
            targetData.value.cliente = '';
            targetData.value.modelo = '';
            targetData.value.placa = '';
            targetData.value.chassi = '';
            targetData.value.tecnico = '';
            servicosAgendado.value = [];
          }
        } 
        loadingOs.value = false;
      }
    }

    async function addNewTask(postData){
      try {
        const response = await addAgendamento(postData);

        if(response.ocupado){
          let htmlOcupados = "<ul>";
          await response.horariosOcupados.forEach(horario => {
            const eventConfig = config.events[horario.codTipoServico - 1];
            const dataTratada = moment(targetData.value.taskDate).format("YYYY-MM-DD");
            const startTime = moment(horario.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
            const endTime = moment(horario.dataFinalServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");

            let startDate = "";
            let endDate = "";

            if (horario.codTipoServico == 10 || horario.codTipoServico == 11){
              startDate = moment(horario.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY");
              endDate = moment(horario.dataFinalServico, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY");
            } else {
              startDate = moment(`${dataTratada} ${startTime}`, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
              endDate = moment(`${dataTratada} ${endTime}`, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
            }

            htmlOcupados += `<li>${startDate} - ${endDate} (${eventConfig.title})</li>`
          });
          htmlOcupados += "</ul>";

          Swal.fire({
            title: response.mensagem,
            html: `<h4>Horário(s) ocupado(s):</h4> ${htmlOcupados}`,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok!",
            customClass: {
              confirmButton: "btn btn-flex background_btn_1_opct text-white",
            },
          });

          return;
        }

        Swal.fire({
          title: 'Agendamento realizado com sucesso!',
          text: 'Deseja agendar um outro serviço para o mesmo cliente?',
          position: 'center',
          showConfirmButton: true,
          reverseButtons: true,
          allowOutsideClick: false,
          showCancelButton: true,
          customClass: {
              confirmButton: "btn btn-flex background_btn_1_opct text-white",
              cancelButton: "btn btn-light me-3",
          },
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          focusConfirm: false,
        }).then((result) => {
          emit("success");
          if (result.isConfirmed) {
            targetData.value.taskType = "";
            targetData.value.taskStartHour = "";
            targetData.value.taskEndHour = "";

            verificaHorariosOcupados();
          } else {
            closeModal();
          }
        });
      }catch(e){
        showTimeAlert("Não foi possível completar a solicitação", "error");
      } finally {
        loading.value = false;
      }
    }

    function desabilitaDatasPassadas(data){
      return moment(data).diff(moment(), 'days') < 0;
    };    

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;

          if(usaChecklist.value != '147'){           
            targetData.value.taskEndDate = targetData.value.taskDate            
          }

          const refOption = tipoOptions.find(option => option.value === targetData.value.taskType);
          const dataTratadaInicial = moment(targetData.value.taskDate).format("YYYY-MM-DD");
          const dataTratadaFinal = moment(targetData.value.taskEndDate).format("YYYY-MM-DD");         
          

          if(targetData.value.formType == "New"){

            // const auxData = moment(targetData.value.taskDate).startOf("day").format("YYYY-MM-DD HH:mm:ss");
            const postData = {
              cnpjConcessionaria: targetData.value.cnpjConcessionaria,
              codConcessionaria: targetData.value.codConcessionaria,
              codAgendamento: targetData.value.codAgendamento,
              codTipoServico: targetData.value.taskType,
              dataInicioServico: moment(dataTratadaInicial + " " + targetData.value.taskStartHour).format("YYYY-MM-DD HH:mm:ss"),
              dataFinalServico: moment(dataTratadaFinal + " " + targetData.value.taskEndHour).format("YYYY-MM-DD HH:mm:ss"),
              dataRecepcao: targetData.value.taskHourRecepcao ? moment(dataTratadaInicial + " " + targetData.value.taskHourRecepcao).format("YYYY-MM-DD HH:mm:ss") : dataTratadaInicial + " " + '00:00:00',
              dataEntrega: targetData.value.taskHourEntrega ? moment(dataTratadaInicial + " " + targetData.value.taskHourEntrega).format("YYYY-MM-DD HH:mm:ss") :  dataTratadaInicial + " " + '00:00:00',
              codTecnico: targetData.value.tecnico,
              cliente: targetData.value.cliente,
              placa: targetData.value.placa,
              chassi: targetData.value.chassi,
              dataAgendamento: moment(dataTratadaInicial + " " + targetData.value.taskStartHour).format("YYYY-MM-DD HH:mm:ss"),
              marca: targetData.value.marca.toUpperCase(),
              modelo: targetData.value.modelo.toUpperCase(),
              consultorAgendado: targetData.value.consultorAgendado,
              osAberta: typeof targetData.value.osAberta === "number" ? targetData.value.osAberta?.toString() : targetData.value.osAberta,
              observacao: targetData.value.detalhes,
            }

            addNewTask(postData);

            emit("addTask", postData);
          } else if(targetData.value.formType == "Edit"){
            const postData = {
              id: targetData.value.codAgendamento,
              title: targetData.value.taskType,
              start:  moment(dataTratadaInicial + " " + targetData.value.taskStartHour).format(),
              end: moment(dataTratadaInicial + " " + targetData.value.taskEndHour).format(),
              resourceId: targetData.value.tecnico,
              extendedProps: {
                cliente: targetData.value.cliente,
                recepcao: moment(dataTratadaInicial + " " + targetData.value.taskHourRecepcao).format(),
                entrega: moment(dataTratadaInicial + " " + targetData.value.taskHourEntrega).format(),
                placa: targetData.value.placa,
                hasContent: refOption?.config.hasContent,
                hasFooter: refOption?.config.hasFooter,
                bgColor: refOption?.config.bgColor,
                headerTextColor: refOption?.config.headerTextColor
              }
            }
            emit("editTask", postData);
          }
        }
      });
    };

    return {
      tecnicoOptions,
      formRef,
      newTargetModalRef,
      loading,
      targetData,
      showContent,
      rules,
      submit,
      initialTaskEndHour,
      initialHourEntrega,
      verificaHorarioInicial,
      verificaHorarioInicialRecepcao,
      tipoOptions,
      ptBr,
      closeModal,
      modeloName,
      desabilitaDatasPassadas,
      consultorOptions,
      loadingOs,
      searchOs,
      verificaHorariosOcupados,
      defaultInitialTimes,
      defaultFinalTimes,
      desabilitaHorariosOcupados,
      usaChecklist,
      servicosAgendado
    };
  },
});
