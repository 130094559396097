export interface LgpdConfig {
    service: {
      placa: boolean;
    };
    programming: {
      placa: boolean;
    };
  }
  
  export interface ParceiroConfig {
    exist: boolean;
    logo: string;
  }

  export interface ReceptionConfig {
    colorProgress: string;
  }
  
  export interface Config {
    color: string;
    logoSemTexto: string;
    logoTexto: string;
    parceiro: ParceiroConfig;
    showPrisma: boolean;
    showOs: boolean;
    showModelo: boolean;
    showDataEntrega: boolean;
    showDataRecepcao: boolean;
    clienteAguarda: boolean;
    lgpd: LgpdConfig;
    reception: ReceptionConfig;
  }
  
  const HYUNDAI_CONFIG: Config = {
    color: 'var(--cor_layout_1)',
    logoSemTexto: './img_marca/hyundai/logo-hyundai-sem-texto.png',
    logoTexto: './img_marca/hyundai/logo-hyundai-texto.png',
    parceiro: {
      exist: true,
      logo: './img_marca/hyundai/logo-shell.png',
    },
    showPrisma: false,
    showOs: false,
    showModelo: false,
    showDataEntrega: true,
    showDataRecepcao: true,
    clienteAguarda: true,
    lgpd: {
      service: {
        placa: true,
      },
      programming: {
        placa: true,
      },
    },
    reception : {
        colorProgress : 'white'
    }
  };
  
  const RENAULT_CONFIG: Config = {
    color: 'var(--cor_renault_agenda)',
    logoSemTexto: './img_marca/renault/renault_sem_texto.png',
    logoTexto: './img_marca/renault/logo-renault-texto.png',
    parceiro: {
      exist: false,
      logo: '',
    },
    showPrisma: true,
    showOs: true,
    showModelo: true,
    showDataEntrega: false,
    showDataRecepcao: false,
    clienteAguarda: false,
    lgpd: {
      service: {
        placa: false,
      },
      programming: {
        placa: true,
      },
    },
    reception : {
        colorProgress : 'black'
    }
  };
  
  const config = {
    hyundaiV1: HYUNDAI_CONFIG,
    renaultV1: RENAULT_CONFIG,
  };
  
  const configScheduleOption = (modelSchedule: string = ''): Config => {
    const formatName = toLowerCaseFirstLetter(modelSchedule);
    return config[formatName] ?? config['hyundaiV1'];
  };
  
  const toLowerCaseFirstLetter = (str: string = ''): string =>
    str.charAt(0).toLowerCase() + str.slice(1);
  
  export { configScheduleOption };
  