
import { defineComponent, onMounted, ref, watch ,reactive} from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import moment from "moment";
import ptBr from 'element-plus/lib/locale/lang/pt-br';
import { addAgendamento, consultaOsAgenda, getHorariosOcupadosMecanicos } from "@/services/AgendaService";
import useAlert from "@/composables/Alert";
import { config } from "@/views/agenda-virtual/ConfigEvents";
import { useStore } from "vuex";
import { ServicoSolicitado } from "@/models/AgendaModel";

export default defineComponent({
  name: "new-event-modal",
  components: {},

  props: {
		initialForm: Object,
    id: String
  },

  emits: ["addTask", "editTask", "success"],

  setup(props, { emit }) {
    const { showConfirmAlert, showTimeAlert } = useAlert();
    const store = useStore();
    const formRef = ref<null | HTMLFormElement>(null);
    const newTargetModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const showContent = ref<any>(true);
    const initialTaskEndHour = ref("");
    const initialHourEntrega = ref("");
    const modeloName = ref("");
    const targetData:any = ref({
      codAgendamento: "",
      detalhes: "",
      cliente: "",
      placa: "",
      taskType: "",
      taskDate: "",
      taskEndDate: "",
      taskStartHour: "",
      taskEndHour: "",
      taskHourEntrega: "",
      taskHourRecepcao: "",
      formType: "",
      tecnico: "",
      dataAgendamento: "",
      marca: "",
      modelo: "",
      osAberta: "",
    });
    const horarios = reactive({
      horaAbertura: '08:00',
      horaFechamento: '19:00',
    })

    const defaultInitialTimes:any = ref();
    const defaultFinalTimes:any = ref([]);
    const usaChecklist = ref('')
    const horariosOcupadosDias: any = ref([])
    const servicosAgendado = ref<ServicoSolicitado[] | []>([])
    const solicitacao = ref<string[] | []>([])
    const servicosAgendadoLoading = ref(false)    

    function horariosQuadro(){
      const concessionaria = store.getters.concessionariaSelectedInfo      
      usaChecklist.value = concessionaria.usaChecklist    
      if(!(Object.keys(concessionaria).length === 0)){
        horarios.horaAbertura =  moment(concessionaria.horaAbertura, "H").format("HH:mm") 
        horarios.horaFechamento =  moment(concessionaria.horaFechamento, "H").format("HH:mm") 
        defaultInitialTimes.value =  geraDefaultTimes(horarios.horaAbertura, horarios.horaFechamento)
      }         
    }

    function geraDefaultTimes(minTime = "07:00", maxTime = "19:00", step = 15, showLastTime = false){
      const refTrueLastTime = moment(maxTime, "HH:mm");
      let refLastTime = refTrueLastTime.clone();
      if(!showLastTime){
        refLastTime.subtract(step, "minutes");
      }

      let auxDefaultTimes:any = [];
      let auxTime = moment(minTime, "HH:mm");
      auxTime.subtract(step, "minutes");

      while(auxTime.clone().isBefore(refLastTime)) {
        auxTime.add(step, "minutes");
        const nextTime = auxTime.clone();

        if(nextTime.clone().isBefore(refTrueLastTime) || (showLastTime && nextTime.isSame(refTrueLastTime))){
          auxDefaultTimes.push({
            text: nextTime.format("HH:mm"),
            value: nextTime.format("HH:mm"),
            disabled: false
          });
        }
      }

      return auxDefaultTimes;
    }

    function closeModal() {
      const modal = document.getElementById(`kt_modal_add_event_${props.id}`);
      hideModal(modal);
    };

    watch(() => props.initialForm, () => {

      servicosAgendado.value = []
      solicitacao.value = []

      //busca os caso tenha
      searchOS(props.initialForm?.osAberta)

      if(props.initialForm?.solicitacao){       
          solicitacao.value = props.initialForm?.solicitacao
      }

      targetData.value = props.initialForm;
      targetData.value.taskEndDate = props.initialForm?.taskDate

      //caso hora agendamento for menor que a hora recepção iniciar sempre da hora de abertura da concessionaria
      dateInitialScheduling()

      const dataDaTarefa = props.initialForm?.taskDate
      const dataMoment = moment(dataDaTarefa);
      const dataAtual = moment();
      targetData.value.taskEndDate = dataDaTarefa;

      // Verifica se a data fornecida é menor que a data atual
      if (dataMoment.isBefore(dataAtual)) {
          const dataAtualMoment = moment(dataAtual.format("DD/MM/YYYY"), "DD/MM/YYYY");
          targetData.value.taskDate = dataAtualMoment;
      }
      verificaHorariosOcupados();
      verificaHorarioInicialRecepcao();
      verificaHorarioInicial();
    });

    const tecnicoOptions:any = ref([]);
    const mecanicos:any = ref([]);
    const horariosOcupados:any = ref([]);
    const concessionariaInfo:any = ref({});
    

    onMounted(() => {
     horariosQuadro() 
        
      concessionariaInfo.value = store.getters.concessionariaSelectedInfo;
      modeloName.value = store.getters.modeloAgendaName;
      if(store.getters.mecanicosList.length > 0){
        mecanicos.value = store.getters.mecanicosList;

        tecnicoOptions.value = mecanicos.value.map(tecnico => {
          const option =  {
            text: tecnico.nome,
            value: tecnico.codMecanico
          }

          return option;
        });
      }
    });

    store.watch(()=>store.getters.concessionariasList,()=> {
      if(store.getters.concessionariasList) horariosQuadro()        
    })

    store.watch(() => store.getters.mecanicosList, () => {
      mecanicos.value = store.getters.mecanicosList;

      tecnicoOptions.value = mecanicos.value.map(tecnico => {
        const option =  {
          text: tecnico.nome,
          value: tecnico.codMecanico
        }

        return option;
      });
    }, { deep: true });

    store.watch(() => store.getters.concessionariaSelectedInfo, () => {
      concessionariaInfo.value = Object.assign({}, store.getters.concessionariaSelectedInfo);
    }, { deep: true });

    // WATCH HOOKS
    store.watch(() => store.getters.modeloAgendaName, () => {
      modeloName.value = store.getters.modeloAgendaName;
    });

    watch(() => targetData.value.taskType, () => {

      if(targetData.value.taskType){
        const refOption = tipoOptions.find(option => option.value === targetData.value.taskType);
        showContent.value = refOption?.config.hasContent;
      } else {
        showContent.value = false;
      }
    });

    const tipoOptions = [
      {
        text: "TESTE",
        value: 1,
        config: {
          hasContent: true,
          hasFooter: true,
          bgColor: "#682E21",
          headerTextColor: "#ffffff"
        },
      },
      {
        text: "RET (Retorno)",
        value: 2,
        config: {
          hasContent: true,
          hasFooter: true,
          bgColor: "black",
          headerTextColor: "#ffffff"
        },
      },
      {
        text: "AL (Alinhamento)",
        value: 3,
        config: {
          hasContent: true,
          hasFooter: true,
          bgColor: "orange",
          headerTextColor: "#ffffff"
        },
      },
      {
        text: "DIAG (Diagnóstico)",
        value: 4,
        config: {
          hasContent: true,
          hasFooter: true,
          bgColor: "red",
          headerTextColor: "#ffffff"
        },
      },
      {
        text: "QS (Quick Service)",
        value: 5,
        config: {
          hasContent: true,
          hasFooter: true,
          bgColor: "#1F3059",
          headerTextColor: "#ffffff"
        },
      },
      {
        text: "MP (Manutenção Periódica)",
        value: 6,
        config: {
          hasContent: true,
          hasFooter: true,
          bgColor: "#darkgreen",
          headerTextColor: "#ffffff"
        },
      },
      {
        text: "RG (Reparo Geral)",
        value: 7,
        config: {
          hasContent: true,
          hasFooter: true,
          bgColor: "lawngreen",
          headerTextColor: "#000000"
        },
      },
      {
        text: "AC (Acessórios)",
        value: 8,
        config: {
          hasContent: true,
          hasFooter: true,
          bgColor: "yellow",
          headerTextColor: "#ffffff"
        },
      },
    ];

    const rules = ref({
      tecnico: [
        {
          required: true,
          message: "Selecione o técnico responsável pelo serviço",
          trigger: "blur",
        },
      ],
      taskType: [
        {
          required: true,
          message: "Selecione o tipo de agendamento",
          trigger: "blur",
        },
      ],
      taskDate: [
        {
          required: true,
          message: "Selecione a data do agendamento",
          trigger: "blur",
        },
      ],
      taskStartHour: [
        {
          required: true,
          message: "Selecione o horário inicial de realização do serviço",
          trigger: "blur",
        },
      ],
      taskEndHour: [
        {
          required: true,
          message: "Selecione o horário final de realização do serviço",
          trigger: "blur",
        },
      ],
      taskHourRecepcao: [
        {
          required: true,
          message: "Selecione o horário de recepção do cliente",
          trigger: "blur",
        },
      ],
      taskHourEntrega: [
        { 
          required: true,
          message: "Selecione o horário de recepção do cliente",
          trigger: "blur"
        }
      ]
    });

    // Função utilitária para buscar horários ocupados
    async function getHorariosOcupados(codConcessionaria, dataFormatada) {
      return await getHorariosOcupadosMecanicos(codConcessionaria, dataFormatada);
    }

    async function verificaHorariosOcupados(){
      const dataInicial = moment(targetData.value.taskDate); // Use taskDate para a data inicial
      const dataFinal = moment(targetData.value.taskEndDate);

      // Comparar apenas o dia, mês e ano, ignorando a hora, minuto e segundo
      const saoIguais = dataInicial.isSame(dataFinal, 'day');

      const dataInicialFormatada = dataInicial.format("YYYY-MM-DD");
      const dataFinalFormatada = dataFinal.format("YYYY-MM-DD");

      if (saoIguais || usaChecklist.value != '147') {
        try {
          const response = await getHorariosOcupadosMecanicos(targetData.value.codConcessionaria, dataInicialFormatada);
          horariosOcupados.value = response;
        } catch (error) {
          showTimeAlert("Não foi possível completar a solicitação", "error");
          closeModal()
        } 
      } else {
        try {
          const [responseDataInicial, responseDataFinal] = await Promise.all([
            getHorariosOcupados(targetData.value.codConcessionaria, dataInicialFormatada),
            getHorariosOcupados(targetData.value.codConcessionaria, dataFinalFormatada)
          ]);

          horariosOcupadosDias.value = responseDataFinal
          horariosOcupados.value = responseDataInicial;       
        } catch (error) {
          showTimeAlert("Não foi possível completar a solicitação", "error");
          closeModal()
        }
      }
     

      desabilitaHorariosOcupados();
    };

    async function desabilitaHorariosOcupados(){
      defaultInitialTimes.value = geraDefaultTimes(horarios.horaAbertura, horarios.horaFechamento);
      verificaHorarioInicial();

      const dataInicial = moment(targetData.value.taskDate); // Use taskDate para a data inicial
      const dataFinal = moment(targetData.value.taskEndDate);

      //caso a data inicial for maior que a data final
      if (dataInicial.isAfter(dataFinal)){
        targetData.value.taskEndDate = targetData.value.taskDate
      }      

      // Comparar apenas o dia, mês e ano, ignorando a hora, minuto e segundo
      const dataInicialEFinalIgual = dataInicial.isSame(dataFinal, 'day');        

      let auxHorarioFinalOcupado = false;
      if(targetData.value.tecnico){
        const horariosOcupadosMecanicoSelecionadoDataFinal = horariosOcupadosDias.value.filter(horario => horario.codTecnico == targetData.value.tecnico && horario.codServico );
        const horariosOcupadosMecanicoSelecionadoDataInicial = horariosOcupados.value.filter(horario => horario.codTecnico == targetData.value.tecnico && horario.codServico );

        const dataComparacao = moment(targetData.value.taskEndDate).startOf('day'); 

        const todasIguais = horariosOcupadosMecanicoSelecionadoDataInicial.every(item => {
            const dataFinalServico = moment(item.dataFinalServico).startOf('day');
            return dataFinalServico.isSame(dataComparacao);
        });
        
        if(dataInicialEFinalIgual || usaChecklist.value != '147'){
         
          horariosOcupadosMecanicoSelecionadoDataInicial.forEach(horario => {
            let auxStartTime:any = moment(horario.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
            auxStartTime = moment(auxStartTime, "HH:mm");

            let auxEndTime:any =  moment(horario.dataFinalServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
            auxEndTime = moment(auxEndTime, "HH:mm");
                  
            const auxDefaultInitialTimes = defaultInitialTimes.value.map(time => {
              if(horario.codTipoServico == 10 || horario.codTipoServico == 11 || moment(time.text, "HH:mm").isBetween(auxStartTime, auxEndTime, undefined, "[)")){
                return {
                  ...time,
                  disabled: true
                };
              } else {
                return time;
              }
            })
            defaultInitialTimes.value = auxDefaultInitialTimes;

              if(!auxHorarioFinalOcupado){  
                  defaultFinalTimes.value = defaultFinalTimes.value.map(time => {
                      if(auxHorarioFinalOcupado || horario.codTipoServico == 10 || horario.codTipoServico == 11 || moment(time.text, "HH:mm").isBetween(auxStartTime, auxEndTime, undefined, "(]")){
                        auxHorarioFinalOcupado = true;
                      
                        return {
                          ...time,
                          disabled: true
                        };
                      } else {
                        return time;
                      }
                    }).filter(time => !time.disabled);
              }    
              
              if(todasIguais){
                  if(horario.codTipoServico == 10 || horario.codTipoServico == 11 || moment(targetData.value.taskStartHour, "HH:mm").isBetween(auxStartTime, auxEndTime, undefined, "[)")) {
                  targetData.value.taskStartHour = "";
                  targetData.value.taskEndHour = "";
                } else if(
                  moment(targetData.value.taskEndHour, "HH:mm").isBetween(auxStartTime, auxEndTime, undefined, "(]") ||
                  (auxEndTime.isBetween(moment(targetData.value.taskStartHour, "HH:mm"), moment(targetData.value.taskEndHour, "HH:mm"), undefined, "(]") &&
                    auxStartTime.isBetween(moment(targetData.value.taskStartHour, "HH:mm"), moment(targetData.value.taskEndHour, "HH:mm"), undefined, "[)")
                  )
                ) {
                  targetData.value.taskEndHour = "";
                }
              } 
          })
          
        }else{

          let lastBlockedEndTime : any = null;
          defaultFinalTimes.value = geraDefaultTimes(horarios.horaAbertura, horarios.horaFechamento);
          
          horariosOcupadosMecanicoSelecionadoDataInicial.forEach(horario => {
              let auxStartTime2 : any = moment(horario.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
              auxStartTime2 = moment(auxStartTime2, "HH:mm");

              let auxEndTime2 : any = moment(horario.dataFinalServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
              auxEndTime2 = moment(auxEndTime2, "HH:mm");

              //aqui pegamos os ultimo horario ocupado para bloquear os anteriores
              defaultInitialTimes.value = defaultInitialTimes.value.map((time, index) => {
                if (horario.codTipoServico == 10 || horario.codTipoServico == 11 || moment(time.text, "HH:mm").isBetween(auxStartTime2, auxEndTime2, undefined, "[)")) {
                  lastBlockedEndTime = lastBlockedEndTime ? moment.max(lastBlockedEndTime, auxEndTime2) : auxEndTime2;
                  return {
                    ...time,
                    disabled: true
                  };
                } else {
                  return time;
                }
              });
                  
          })

          //logica para pega o ultimo horario ocupado e bloquear todos antes dele 
          if (lastBlockedEndTime) {
            defaultInitialTimes.value = defaultInitialTimes.value.map((time) => {
              if (moment(time.text, "HH:mm").isSameOrBefore(lastBlockedEndTime, 'minute')) {
                return {
                  ...time,
                  disabled: true
                };
              } else {
                return time;
              }
            });
          }

          const novasHoras = geraDefaultTimes(horarios.horaAbertura, horarios.horaFechamento);
          let isFirstIteration = true;

          horariosOcupadosMecanicoSelecionadoDataFinal.forEach(horario => {
              let auxStartTime2: any = moment(horario.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
              auxStartTime2 = moment(auxStartTime2, "HH:mm");

              let auxEndTime2: any = moment(horario.dataFinalServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
              auxEndTime2 = moment(auxEndTime2, "HH:mm");

              // todos horarios depois do primeiro horario ocupado e bloqueado
              defaultFinalTimes.value = novasHoras.map(time => {
                  if (
                      horario.codTipoServico == 10 ||
                      horario.codTipoServico == 11 ||
                      (moment(time.text, "HH:mm").isBetween(auxStartTime2, auxEndTime2, undefined, "(]") && isFirstIteration)
                  ) {
                      isFirstIteration = false;
                      return {
                          ...time,
                          disabled: true,
                      };
                  } else if (!isFirstIteration) {
                      return {
                          ...time,
                          disabled: true,
                      };
                  } else {
                      return time;
                  }
              });
          });         
        } 
      }      
    }

    function verificaHorarioInicial() {
      const startHour = moment(targetData.value.taskStartHour, "HH:mm");
      const auxStartHour = startHour.clone();
      const endHour = moment(targetData.value.taskEndHour, "HH:mm");

      defaultFinalTimes.value = geraDefaultTimes(auxStartHour.clone().add(15, "minutes").format("HH:mm"), horarios.horaFechamento, 15, true);

      if(usaChecklist.value !== '147'){
        if(endHour && endHour.isBefore(startHour)){
         targetData.value.taskEndHour = null;
        }
      }  
    }

    function verificaHorarioInicialRecepcao() {
      const startHour = moment(targetData.value.taskHourRecepcao, "HH:mm");
      const auxStartHour = startHour.clone();
      const endHour = moment(targetData.value.taskHourEntrega, "HH:mm");

      initialHourEntrega.value = auxStartHour.clone().add(15, "minutes").format("HH:mm");

      if(endHour && endHour.isBefore(startHour)){
        targetData.value.taskHourEntrega = null;
      }
    }

    async function addNewTask(postData){
      try {
        const response = await addAgendamento(postData);

        if(response.ocupado){
          let htmlOcupados = "<ul>";
          await response.horariosOcupados.forEach(horario => {
            const eventConfig = config.events[horario.codTipoServico - 1];
            const dataTratada = moment(targetData.value.taskDate).format("YYYY-MM-DD");
            const startTime = moment(horario.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
            const endTime = moment(horario.dataFinalServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");

            let startDate = "";
            let endDate = "";

            if (horario.codTipoServico == 10 || horario.codTipoServico == 11){
              startDate = moment(horario.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY");
              endDate = moment(horario.dataFinalServico, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY");
            } else {
              startDate = moment(`${dataTratada} ${startTime}`, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
              endDate = moment(`${dataTratada} ${endTime}`, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
            }

            htmlOcupados += `<li>${startDate} - ${endDate} (${eventConfig.title})</li>`
          });
          htmlOcupados += "</ul>";

          Swal.fire({
            title: response.mensagem,
            html: `<h4>Horário(s) ocupado(s):</h4> ${htmlOcupados}`,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok!",
            customClass: {
              confirmButton: "btn btn-flex background_btn_1_opct text-white",
            },
          });

          return;
        }

        Swal.fire({
          title: 'Agendamento realizado com sucesso!',
          text: 'Deseja agendar um outro serviço para o mesmo cliente?',
          position: 'center',
          showConfirmButton: true,
          reverseButtons: true,
          allowOutsideClick: false,
          showCancelButton: true,
          customClass: {
              confirmButton: "btn btn-flex background_btn_1_opct text-white",
              cancelButton: "btn btn-light me-3",
          },
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          focusConfirm: false,
        }).then((result) => {
          emit("success");
          if (result.isConfirmed) {
            targetData.value.taskType = "";
            targetData.value.taskStartHour = "";
            targetData.value.taskEndHour = "";
            
            verificaHorariosOcupados();
          } else {
            closeModal();
          }
        });
      }catch(e){
        showTimeAlert("Não foi possível completar a solicitação", "error");
      } finally {
        loading.value = false;
      }
    }

    function desabilitaDatasPassadas(data){
      return moment(data).diff(moment(), 'days') < 0;
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;

          if(usaChecklist.value != '147'){           
            targetData.value.taskEndDate = targetData.value.taskDate            
          }

          const refOption = tipoOptions.find(option => option.value === targetData.value.taskType);
          const dataTratadaInicial = moment(targetData.value.taskDate).format("YYYY-MM-DD");
          const dataTratadaFinal = moment(targetData.value.taskEndDate).format("YYYY-MM-DD");
          
          if(targetData.value.formType == "New"){
            const postData = {
              cnpjConcessionaria: targetData.value.cnpjConcessionaria,
              codConcessionaria: targetData.value.codConcessionaria,
              codAgendamento: targetData.value.codAgendamento,
              codTipoServico: targetData.value.taskType,
              dataInicioServico: moment(dataTratadaInicial + " " + targetData.value.taskStartHour).format("YYYY-MM-DD HH:mm:ss"),
              dataFinalServico: moment(dataTratadaFinal + " " + targetData.value.taskEndHour).format("YYYY-MM-DD HH:mm:ss"),
              dataRecepcao: moment(dataTratadaInicial + " " + targetData.value.taskHourRecepcao).format("YYYY-MM-DD HH:mm:ss"),
              dataEntrega: targetData.value.taskHourEntrega ? moment(dataTratadaInicial + " " + targetData.value.taskHourEntrega).format("YYYY-MM-DD HH:mm:ss") : dataTratadaInicial + " " + '00:00:00',
              codTecnico: targetData.value.tecnico,
              cliente: targetData.value.cliente,
              placa: targetData.value.placa,
              dataAgendamento: moment(targetData.value.dataAgendamento, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD HH:mm:ss"),
              marca: targetData.value.marca,
              modelo: targetData.value.modelo,
              chassi: targetData.value.chassi,
              consultorAgendado: targetData.value.consultorAgendado,
              osAberta: targetData.value.osAberta,
              observacao: targetData.value.detalhes,
            }

            addNewTask(postData);

            emit("addTask", postData);
          } else if(targetData.value.formType == "Edit"){
            const postData = {
              id: targetData.value.codAgendamento,
              title: targetData.value.taskType,
              start:  moment(dataTratadaInicial + " " + targetData.value.taskStartHour).format(),
              end: moment(dataTratadaFinal + " " + targetData.value.taskEndHour).format(),
              resourceId: targetData.value.tecnico,
              extendedProps: {
                cliente: targetData.value.cliente,
                recepcao: moment(dataTratadaInicial + " " + targetData.value.taskHourRecepcao).format(),
                entrega: moment(dataTratadaInicial + " " + targetData.value.taskHourEntrega).format(),
                placa: targetData.value.placa,
                hasContent: refOption?.config.hasContent,
                hasFooter: refOption?.config.hasFooter,
                bgColor: refOption?.config.bgColor,
                headerTextColor: refOption?.config.headerTextColor
              }
            }
            emit("editTask", postData);
          }
        }
      });
    };

    const searchOS = (numberOS : string) : void => {
      if(numberOS){
        const postData = {
            numeroOs: props.initialForm?.osAberta,
            codConcessionaria: concessionariaInfo.value.codConcessionaria
        }

        servicosAgendadoLoading.value = true
        consultaOsAgenda(postData).then((response) => {
          servicosAgendadoLoading.value = false
          servicosAgendado.value = Array.isArray(response.servicos['Servico']) ? response.servicos['Servico'] : [response.servicos['Servico']] 
        }).catch(() =>  {
          servicosAgendado.value = []          
          servicosAgendadoLoading.value = false
        });
      }
    }

    function normalizeTime(time : string = '') : string {
      try {
        const format = `${time}:00`
        const [hours, minutes] = format.split(':');
        const normalizedHours = hours.padStart(2, '0'); 

        return `${normalizedHours}:${minutes}`;
      } catch (error) {
        return time
      }      
    }

    function dateInitialScheduling() : void{
      const hourScheduling = moment(targetData.value.taskStartHour, "HH:mm");
      const openingTime = moment(normalizeTime(store.getters.concessionariaSelectedInfo.horaAbertura), "HH:mm");

      if (hourScheduling.isBefore(openingTime)) {  
        targetData.value.taskStartHour = normalizeTime(store.getters.concessionariaSelectedInfo.horaAbertura) ;      
      }
    }
    
    return {
      tecnicoOptions,
      formRef,
      newTargetModalRef,
      loading,
      targetData,
      showContent,
      rules,
      submit,
      initialTaskEndHour,
      initialHourEntrega,
      verificaHorarioInicial,
      verificaHorarioInicialRecepcao,
      tipoOptions,
      ptBr,
      closeModal,
      modeloName,
      desabilitaDatasPassadas,
      verificaHorariosOcupados,
      defaultInitialTimes,
      defaultFinalTimes,
      desabilitaHorariosOcupados,
      horarios,
      usaChecklist,
      servicosAgendado,
      solicitacao,
      servicosAgendadoLoading
    };
  },
});
