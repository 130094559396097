import { useStore } from "vuex";
import {ref} from "vue";
import moment from "moment";
import { configScheduleOption,Config } from '@/views/agenda-virtual/utils/ConfigScheduleUtils'
import useUtilChanges from "@/composables/UtilChanges";


export const generate = () => {

  const store = useStore();
  const { replacePlaca, cutChassi } = useUtilChanges();
  const modeloAgendaSelect = ref(store.getters.modeloAgendaName)
  const configSchedule = ref<Config>(configScheduleOption(modeloAgendaSelect.value))

 
  const setModelSchedule = () => {

    modeloAgendaSelect.value = store.getters.modeloAgendaName
    configSchedule.value =configScheduleOption(modeloAgendaSelect.value)   
  }

  const chipPrograming = (info) => {
    const start = moment(info.event.start);
    const end = moment(info.event.end);

    const eventInfo = info.event;
    
    const propriedades = eventInfo.extendedProps;
    let htmlContent =  "";
    let htmlFooter =  "";
    let htmlWait =  "";
    let htmlDuration =  "";
    let htmlTimeAdditional =  "";
    let tempoAdicional = 0;
    const tiposServico = [9,10,11,12]
    
    const duration = moment.duration(end.diff(start)).asMinutes();

    if(propriedades.tempoAdicional){
      tempoAdicional = 4.333 * propriedades.tempoAdicional
      
      // htmlTimeAdditional = `<span class='title-time-additional'>+${propriedades.tempoAdicional}   min</span>`
      htmlTimeAdditional = ''
    }
 
    if(propriedades.hasContent){
      htmlContent = `
      <div class="event-content ">
        <div class="${configSchedule.value?.showDataRecepcao ? 'event-content-info' : 'd-none' }">
          <div>${propriedades.showDuration && duration > 15 ? 'Recepção' : 'R'}:</div>
          <div  class="info-date ${duration <= 15 ? 'd-none' : 'd-none'}">${moment(propriedades.recepcao).format("DD/MM")}</div>
          <div  class"info-time">${moment(propriedades.recepcao).format(" HH:mm")}</div>
        </div>
          <div class="${configSchedule.value?.showDataEntrega ? 'event-content-info' : 'd-none' }">
            <div class="info-label">${propriedades.showDuration && duration > 15 ? 'Entrega' : 'E'}:</div>
            <div  class="info-date ${duration <= 15 ? 'd-none' : 'd-none'}">${moment(propriedades.entrega).format("DD/MM")}</div>
            <div  class="info-time">${moment(propriedades.entrega).format("HH:mm")}</div>
          </div>
        <div class="event-content-info info-label w-100  d-flex justify-content-between">
          <div class="${configSchedule.value?.showOs ? '' : 'd-none'}">${propriedades.allInfo.osAberta}</div>
          <div class="${configSchedule.value.showPrisma ? '' : 'd-none'}">${propriedades.prisma}</div>
        </div> 
        <div class="event-content-info info-label w-100  d-flex justify-content-between">
          <div class="${configSchedule.value?.showModelo ? 'text-truncate w-100' : 'd-none'}">${propriedades.modelo}</div>
        </div>           
      </div>`;
      htmlWait =  `<div class="${configSchedule.value?.clienteAguarda ? 'event-wait' : 'd-none' }">${propriedades.aguarda ? '<div class="wait-indicator"></div>' : ''}</div>`
    }

    if(propriedades.hasFooter){
      htmlFooter = `
      <div style="${'color:' + propriedades.footerTextColor + ';' + 
        // (tempoAdicional ? 'background: linear-gradient(to right, transparent 0%, transparent calc(100% - ' + tempoAdicional + 'px), var(--cor_layout_1) calc(100% - ' + tempoAdicional + 'px), var(--cor_layout_1) 100%);' : '') +
        'background-color:' + propriedades.bgColor}" class="event-footer ${duration <= 15 ? 'flex-column' : ''}">
        <div class="text-overflow ${duration <= 15 ? 'd-none' : ''}">
          <svg class="d-none" width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.9375 4.5C5.18027 4.5 6.1875 3.49277 6.1875 2.25C6.1875 1.00723 5.18027 0 3.9375 0C2.69473 0 1.6875 
              1.00723 1.6875 2.25C1.6875 3.49277 2.69473 4.5 3.9375 4.5ZM5.5125 5.0625H5.21895C4.82871 5.2418 4.39453 5.34375
              3.9375 5.34375C3.48047 5.34375 3.04805 5.2418 2.65606 5.0625H2.3625C1.0582 5.0625 0 6.1207 0 7.425V8.15625C0 8.62207 
              0.37793 9 0.84375 9H7.03125C7.49707 9 7.875 8.62207 7.875 8.15625V7.425C7.875 6.1207 6.8168 5.0625 5.5125 5.0625Z" fill="#464E5F"/>
              </svg>${propriedades.consultorNome}</div><div class="text-overflow">
                <svg class="d-none" width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.5" width="12.0909" height="8" rx="1.5" stroke="#464E5F"/>
                    <path d="M0 2C0 0.89543 0.895431 0 2 0H11.0909C12.1955 0 13.0909 0.895431 13.0909 2V4.5H0V2Z" fill="#464E5F"/></svg>
                    ${ propriedades.placa ? 
                      configSchedule.value?.lgpd.programming.placa ? replacePlaca(propriedades.placa) : propriedades.placa ?? ''
                      : cutChassi(propriedades.chassi,-6,'...')
                    }
        </div>
      </div>`;
    }

    if(propriedades.showDuration && duration > 15){
      // htmlDuration =  `- ${tempoAdicional ? duration - propriedades.tempoAdicional : duration}min`;
      htmlDuration = `- ${duration}min`
    }
    
    const html = `
      <div class="event" style="${propriedades.osAberta || tiposServico.includes(propriedades.codTipoServico) ? '' :'opacity: 50%;'} ${!propriedades.hasContent ? 'background-color:' + propriedades.bgColor : 'background-color: white'}">
        <div class="event-header" style="             
          color: ${propriedades.headerTextColor}; background-color: ${propriedades.bgColor}">
          <span class="event-title ${duration <= 15 ? 'event-title-min' : ''} ${propriedades.tempoAdicional ? 'event-title-additional-time' : ''} ${propriedades.allInfo.codTipoServico > 8 ? 'text-truncate' : '' }" style="color: ${propriedades.headerTextColor}">${propriedades.status == 5 ? '<i style="color:#ffffff" class="bi bi-stopwatch-fill"></i>' : '' } ${eventInfo.title} ${htmlDuration} ${htmlTimeAdditional}</span>${htmlWait}</div>${htmlContent}${htmlFooter}</div>`;
    return { html };
  }

  const chipService = (info) => {
    const start = moment(info.event.start);
    const end = moment(info.event.end);

    const eventInfo = info.event;

    const propriedades = eventInfo.extendedProps;
    let htmlContent =  "";
    let htmlFooter =  "";
    let htmlWait =  "";
    let htmlDuration =  "";
    let htmlTimeAdditional =  "";
    let tempoAdicional = 0;
    const tiposServico = [9,10,11,12] //eventos extra
    
    const duration = moment.duration(end.diff(start)).asMinutes();

    if(propriedades.tempoAdicional){
      tempoAdicional = 4.333 * propriedades.tempoAdicional
      
      htmlTimeAdditional = `<span class='title-time-additional'>+${propriedades.tempoAdicional}  ${propriedades.tempoAdicional > 15 ? 'min': ''}</span>`
    }

    if(propriedades.hasContent){
      htmlContent = `
      <div class="event-content ">
        <div class="${configSchedule.value?.showDataRecepcao ? 'event-content-info' : 'd-none' }">
          <div>${propriedades.showDuration && duration > 15 ? 'Recepção' : 'R'}:</div>
          <div  class="info-date ${duration <= 15 ? 'd-none' : 'd-none'}">${moment(propriedades.recepcao).format("DD/MM")}</div>
          <div  class"info-time">${moment(propriedades.recepcao).format(" HH:mm")}</div>
        </div>
          <div class="${configSchedule.value?.showDataEntrega ? 'event-content-info' : 'd-none' }">
            <div class="info-label">${propriedades.showDuration && duration > 15 ? 'Entrega' : 'E'}:</div>
            <div  class="info-date ${duration <= 15 ? 'd-none' : 'd-none'}">${moment(propriedades.entrega).format("DD/MM")}</div>
            <div  class="info-time">${moment(propriedades.entrega).format("HH:mm")}</div>
          </div>
        <div class="event-content-info info-label w-100  d-flex justify-content-between">
          <div class="${configSchedule.value?.showOs ? '' : 'd-none'}">${propriedades.allInfo.osAberta}</div>
          <div class="${configSchedule.value?.showPrisma ? '' : 'd-none'}">${propriedades.prisma}</div>
        </div> 
        <div class="event-content-info info-label w-100  d-flex justify-content-between">
          <div class="${configSchedule.value?.showModelo ? 'text-truncate w-100' : 'd-none'}">${propriedades.modelo}</div>
        </div>           
      </div>`;
      htmlWait =  `<div class="${configSchedule?.value.clienteAguarda ? 'event-wait' : 'd-none' }">${propriedades.aguarda ? '<div class="wait-indicator"></div>' : ''}</div>`
    }

    if(propriedades.hasFooter){
      htmlFooter = `
        <div style="${"color:" + propriedades.footerTextColor + ';'} ${'color:' + propriedades.footerTextColor + ';' +
          (tempoAdicional ? 'background: linear-gradient(to right, transparent 0%, transparent calc(100% - ' + tempoAdicional + 'px), var(--cor_layout_1) calc(100% - ' + tempoAdicional + 'px), var(--cor_layout_1) 100%);' : '')} ${ 'background-color:' + propriedades.bgColor}" class="event-footer ${duration <= 15 ? 'flex-column' : ''}">
          <div class="text-overflow ${duration <= 15 ? 'd-none' : ''}">
            <svg class="d-none" width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.9375 4.5C5.18027 4.5 6.1875 3.49277 6.1875 2.25C6.1875 1.00723 5.18027 0 3.9375 0C2.69473 0 1.6875 
              1.00723 1.6875 2.25C1.6875 3.49277 2.69473 4.5 3.9375 4.5ZM5.5125 5.0625H5.21895C4.82871 5.2418 4.39453 5.34375 
              3.9375 5.34375C3.48047 5.34375 3.04805 5.2418 2.65606 5.0625H2.3625C1.0582 5.0625 0 6.1207 0 7.425V8.15625C0
              8.62207 0.37793 9 0.84375 9H7.03125C7.49707 9 7.875 8.62207 7.875 8.15625V7.425C7.875 6.1207 6.8168 5.0625 5.5125 5.0625Z" fill="#464E5F"/>
            </svg>${propriedades.consultorNome}</div><div class="text-overflow"><svg class="d-none" width="14" height="9" viewBox="0 0 14 9" fill="none" 
              xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="12.0909" height="8" rx="1.5" stroke="#464E5F"/><path d="M0 2C0 0.89543 0.895431 0 2
              0H11.0909C12.1955 0 13.0909 0.895431 13.0909 2V4.5H0V2Z" fill="#464E5F"/>
            </svg> ${ propriedades.placa ? 
                      configSchedule.value?.lgpd.service.placa ? replacePlaca(propriedades.placa) : propriedades.placa ?? ''
                      : cutChassi(propriedades.chassi,-6,'...')
                    }
          </div>
        </div>`;
    }

    if(propriedades.showDuration && duration > 15){
      htmlDuration =  `- ${tempoAdicional ? duration - propriedades.tempoAdicional : duration} min`;
    }
    
    const html = `
      <div class="event" 
        style="${propriedades.osAberta || tiposServico.includes(propriedades.codTipoServico) ? '' :'opacity: 50%;'} 
          ${!propriedades.hasContent ? 'background-color:' + propriedades.bgColor : 'background-color: white'}">
        <div class="event-header" 
          style="${(tempoAdicional ? 'background: linear-gradient(to right, transparent 0%, transparent calc(100% - ' + tempoAdicional + 'px), var(--cor_layout_1) calc(100% - ' + tempoAdicional + 'px), var(--cor_layout_1) 100%);' : '')};
          color: ${propriedades.headerTextColor}; background-color: ${propriedades.bgColor}">
          <span 
            class="event-title ${duration <= 15 ? 'event-title-min' : ''} ${propriedades.tempoAdicional ? 'event-title-additional-time' : ''} ${propriedades.allInfo.codTipoServico > 8 ? 'text-truncate' : '' }" 
            style="color: ${propriedades.headerTextColor}">${propriedades.status == 5 ? '<i style="color:#ffffff" class="bi bi-stopwatch-fill"></i>' : '' } ${eventInfo.title} ${htmlDuration} ${htmlTimeAdditional}
          </span>
            ${htmlWait}
        </div>${htmlContent}${htmlFooter}</div>`;
    return { html };
  }

  return { 
    chipPrograming,
    chipService,
    setModelSchedule
  }
}
