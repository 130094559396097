
    import { defineComponent, onMounted, ref, watch } from 'vue';
    import { ElConfigProvider } from 'element-plus';
    import ptBr from 'element-plus/lib/locale/lang/pt-br';
    import moment from 'moment';
    import useEmitter from '@/composables/Emmiter'
    import { useRoute } from 'vue-router';
    import usePDF from '@/composables/Pdf'
    import { Modal } from "bootstrap";
    import AuthService from "@/services/AuthService";

    export default defineComponent({
        name: "agendamento-filtro-data-modal",
        components: {
            ElConfigProvider,
        },
        emits: ["filtrarModal"],

        props: {
            id: {
                type: String,
                default: "filter-modal"
            },
        },

        setup(props, { emit }) {
            const { exportBlob } = usePDF();
            const emitter = useEmitter();
            const route = ref(useRoute());
            const userInfo = AuthService.getUsuarioAuth();
            
            const dataMesAtual = moment().startOf("day").toISOString();
            // const dataInicialPeriodo = moment().startOf("day").subtract(30, 'days').toISOString();
            // const dataFInalPeriodo = moment().endOf("day").toISOString();
           
            
            // const periodoTrintaDias = [dataInicialPeriodo, dataFInalPeriodo];
            
            const datePicker:any = ref(dataMesAtual);
            const lastEmitIsNull = ref(false);
        	const loading = ref(false);
            const modalFiltro:any = ref();


            onMounted(()=>{
                /* const refModal = document.getElementById(props.id);
                    if(refModal){
                    document.getElementById("layout_content")?.appendChild(refModal);
                }  */
            })

             function openModal(){
                const refModal = document.getElementById(props.id);
                modalFiltro.value = new Modal(refModal);

                modalFiltro.value.show();
            }

            function retornaFiltroData(dataSelected){
                dataSelected = moment(dataSelected).format();
                dataSelected = dataSelected.substr(0, 10) + " 00:00:00";

                const filters = `${dataSelected}`;
                
                return filters;
            };

            function emiteMudanca(valorData){
                if(valorData == null){
                    if(lastEmitIsNull.value){
                        return;
                    };
                    
                    lastEmitIsNull.value = true;
                    emit("filtrarModal", retornaFiltroData(dataMesAtual));
                    return;
                };

                lastEmitIsNull.value = false;
                const dataTratada = retornaFiltroData(valorData);
                emit("filtrarModal", {data: dataTratada});
            };

            watch(datePicker, () => {
                if(datePicker.value == null){
                    datePicker.value = dataMesAtual;
                    return;
                };
            });

            
            function desabilitaDatasFuturas (data){
                const dataFutura = data.getTime() > Date.now();
                return dataFutura;
            };

            return {
                openModal,
                modalFiltro,
                loading, 
                ptBr, 
                datePicker, 
                emitter, 
                emiteMudanca, 
                dataMesAtual, 
                lastEmitIsNull, 
                route, 
                desabilitaDatasFuturas, 
            };
        }
    });
