
import { defineComponent, onMounted, ref, watch, reactive} from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import moment from "moment";
import ptBr from 'element-plus/lib/locale/lang/pt-br';
import { config } from "@/views/agenda-virtual/ConfigEvents";
import { addAgendamentoMecanico, deletarAgendamento } from "@/services/AgendaService";
import useAlert from "@/composables/Alert";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "new-event-modal",
  components: {},

  props: {
		initialForm: Object,
  },
  emits: ["addTask", "editTask", "success"],

  setup(props, { emit }) {
    const store = useStore();
    const { showConfirmAlert, showTimeAlert } = useAlert();
    const formRef = ref<null | HTMLFormElement>(null);
    const newTargetModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const showContent = ref<any>(true);
    const initialTaskEndHour = ref("");
    const initialHourEntrega = ref("");
    const horarios = reactive({
      horaAbertura: '08:00',
      horaFechamento: '19:00',
    })
    const targetData:any = ref({
      datePicker: [],
      codAgendamento: "",
      detalhes: "",
      cliente: "",
      placa: "",
      taskType: "",
      taskDate: "",
      taskStartHour: "",
      taskEndHour: "",
      taskHourEntrega: "",
      taskHourRecepcao: "",
      formType: "",
      tecnico: "",
      dataAgendamento: "",
      marca: "",
      modelo: "",
      osAberta: "",
    });
    const tecnicoOptions:any = ref([]);
    const mecanicos:any = ref([]);
    const concessionariaInfo:any = ref({});

    function horariosQuadro(){
      const concessionaria = store.getters.concessionariaSelectedInfo
      if(!(Object.keys(concessionaria).length === 0)){
        horarios.horaAbertura =  moment(concessionaria.horaAbertura, "H").format("HH:mm") 
        horarios.horaFechamento =  moment(concessionaria.horaFechamento, "H").format("HH:mm")
      }         
    }

    onMounted(() => {
       horariosQuadro() 

      concessionariaInfo.value = store.getters.concessionariaSelectedInfo;
 
      if(store.getters.mecanicosList.length > 0){
        mecanicos.value = store.getters.mecanicosList;

        tecnicoOptions.value = mecanicos.value.map(tecnico => {
          const option =  {
            text: tecnico.nome,
            value: tecnico.codMecanico
          }

          return option;
        });
      }
    });

    store.watch(()=>store.getters.concessionariasList,()=> {
        if(store.getters.concessionariasList) horariosQuadro()        
      })

    store.watch(() => store.getters.mecanicosList, () => {
      mecanicos.value = store.getters.mecanicosList;

      tecnicoOptions.value = mecanicos.value.map(tecnico => {
        const option =  {
          text: tecnico.nome,
          value: tecnico.codMecanico
        }

        return option;
      });
    }, { deep: true });

    store.watch(() => store.getters.concessionariaSelectedInfo, () => {
      concessionariaInfo.value = Object.assign({}, store.getters.concessionariaSelectedInfo);
    }, { deep: true });

    watch(() => targetData.value.taskType, () => {
      if(targetData.value.taskType){
        const refOption = tipoOptions.find(option => option.value === targetData.value.taskType);
        showContent.value = refOption?.config.hasContent;
      } else {
        showContent.value = false;
      }
    });

    function closeModal() {
      const modal = document.getElementById("kt_modal_event_mecanico");
      hideModal(modal);
    };

    const validaPeriodo = (rule: any, value: any, callback: any) => {
      const dataInicio = moment(value[0]);
      const dataFinal = moment(value[1]);
      if (!value[0] || !value[1]) {
        callback(new Error('Selecione o período'));
        return;
      }
      if (dataFinal.diff(dataInicio, "days") >= 60 && targetData.value.taskType == 9) {
        callback(new Error('Somente é possivel cadastar o almoço de 2 meses por vez'));
        return;
      }
      return true;
    }

    const tipoOptions = [
      {
        text: "Almoço",
        value: 9,
        config: {
          hasContent: false,
          hasFooter: false,
          bgColor: "#ff0000",
          headerTextColor: "#ffffff"
        },
      },
      {
        text: "Férias",
        value: 10,
        config: {
          hasContent: false,
          hasFooter: false,
          bgColor: "#ff0000",
          headerTextColor: "#ffffff"
        },
      },
      {
        text: "Licença Médica",
        value: 11,
        config: {
          hasContent: false,
          hasFooter: false,
          bgColor: "#ff0000",
          headerTextColor: "#ffffff"
        },
      },
      {
        text: "Outros",
        value: 12,
        config: {
          hasContent: false,
          hasFooter: false,
          bgColor: "#ff0000",
          headerTextColor: "#ffffff"
        },
      },
    ];

    const rules = ref({
      detalhes: [
        {
          required: true,
          message: "Preencha o nome do agendamento",
          trigger: "blur",
        },
      ],
      tecnico: [
        {
          required: true,
          message: "Selecione o técnico responsável pelo serviço",
          trigger: "blur",
        },
      ],
      taskType: [
        {
          required: true,
          message: "Selecione o tipo de agendamento",
          trigger: "blur",
        },
      ],
      taskDate: [
        {
          required: true,
          message: "Selecione a data do agendamento",
          trigger: "blur",
        },
      ],
      taskStartHour: [
        {
          required: true,
          message: "Selecione o horário inicial",
          trigger: "blur",
        },
      ],
      taskEndHour: [
        {
          required: true,
          message: "Selecione o horário final",
          trigger: "blur",
        },
      ],
      datePicker: [
        {
          validator: validaPeriodo,
          type: 'array',
        },
      ],
    });

    const makeRange = (start: number, end: number) => {
      const result: number[] = []
      for (let i = start; i <= end; i++) {
        result.push(i)
      }
      return result
    }

    function verificaHorarioInicial() {
      const startHour = moment(targetData.value.taskStartHour, "HH:mm");
      const endHour = moment(targetData.value.taskEndHour, "HH:mm");
      initialTaskEndHour.value = startHour.add(15, "minutes").format("HH:mm");

      if(endHour && endHour.isBefore(startHour)){
        targetData.value.taskEndHour = null;
      }
    }

    function verificaHorarioInicialRecepcao() {
      const startHour = moment(targetData.value.taskHourRecepcao, "HH:mm");
      const endHour = moment(targetData.value.taskHourEntrega, "HH:mm");
      initialHourEntrega.value = startHour.add(15, "minutes").format("HH:mm");

      if(endHour && endHour.isBefore(startHour)){
        targetData.value.taskHourEntrega = null;
      }
    }

    function disabledHours() {
      return makeRange(0, 6).concat(makeRange(18, 23))
    }

    async function addNewTask(postData){
      try {
        loading.value = true;
        const response = await addAgendamentoMecanico(postData);
        if(response.ocupado){
          loading.value = false;
          const arrayCodServico = response.horariosOcupados.map(horario =>  horario.codServico);
          let htmlOcupados = "<ul>";
          await response.horariosOcupados.forEach(horario => {
            const eventConfig = config.events[horario.codTipoServico - 1];
            
            let tecnicoInfo:any = {};
            tecnicoInfo = mecanicos.value.find(tecnico => {
              const mecanico:any = tecnico;
              return mecanico.codMecanico == horario.codTecnico;
            });
            

            if(horario.codTipoServico == 10 || horario.codTipoServico == 11){
              const startTime = moment(horario.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY");
              const endTime = moment(horario.dataFinalServico, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY");
              htmlOcupados += `<li>${trataNome(tecnicoInfo.nome)}: ${startTime} - ${endTime} (${eventConfig.title})</li>`
            } else {
              const startTime = moment(horario.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm");
              const endTime = moment(horario.dataFinalServico, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm");
              htmlOcupados += `<li>${trataNome(tecnicoInfo.nome)}: ${startTime} - ${endTime} (${eventConfig.title})</li>`
            }
          });
          htmlOcupados += "</ul>";
          if(postData.codTipoServico != 10 && postData.codTipoServico != 11){
            Swal.fire({
              title: response.mensagem,
              html: `<h4>Horário(s) ocupado(s):</h4> ${htmlOcupados}`,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok!",
              customClass: {
                confirmButton: "btn btn-flex background_btn_1_opct text-white",
              },
            });
          } else {
            Swal.fire({
              title: response.mensagem,
              html: `<h4>Horário(s) ocupado(s):</h4> ${htmlOcupados}`,
              icon: "error",
              buttonsStyling: false,
              showCancelButton: true,
              confirmButtonText: "Deletar serviços",
              cancelButtonText: "Ok!",
              reverseButtons: true,
              customClass: {
                confirmButton: "btn btn-flex background_btn_1_opct text-white",
                cancelButton: "btn btn-light me-3",
              },
            }).then((result) => {
              if (result.isConfirmed) {
                Swal.fire({
                  title: 'Todos serviços que ocupam o horário seram deletados',
                  text: 'A ação não poderá ser desfeita, deseja continuar?',
                  position: 'center',
                  showConfirmButton: true,
                  reverseButtons: true,
                  allowOutsideClick: false,
                  showCancelButton: true,
                  customClass: {
                      confirmButton: "btn btn-flex background_btn_1_opct text-white",
                      cancelButton: "btn btn-light me-3",
                  },
                  confirmButtonText: "Sim",
                  cancelButtonText: "Não",
                  focusConfirm: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    const deletarPostData = {
                      codServico: arrayCodServico
                    };
                    deletarAgendamento(deletarPostData).then((result) => {
                      showTimeAlert(result);
                      addNewTask(postData);
                    }).catch((e) => {
                      showTimeAlert("Não foi possível completar a solicitação", "error");
                    })
                  }
                });
              }
            });
          }
          return;
        }
        showTimeAlert("O evento foi vinculado ao técnico");
        closeModal();
        store.dispatch(Actions.SET_BASE_SERVICOS);
        emit("success");
      } catch(e){
        console.error(e);
        showTimeAlert("Não foi possível completar a solicitação", "error");
      } finally {
        loading.value = false;
      }
    }

    function trataNome(nome){
      if(!nome || nome == "undefined" || nome == undefined){
        return "";
      }
      
      const arrayNomes = nome.split(" ");
      const nomeTratado = `${arrayNomes[0]} ${arrayNomes[arrayNomes.length - 1]}` 

      return nomeTratado;
    }

    function prepareForm(){
      let postData;

      if(targetData.value.taskType == 9){
        postData = {
          cnpjConcessionaria: concessionariaInfo.value.cnpj,
          codConcessionaria: concessionariaInfo.value.codConcessionaria,
          codTipoServico: targetData.value.taskType,
          dataInicioServico: moment(moment(targetData.value.datePicker[0]).format("YYYY-MM-DD") + " " + targetData.value.taskStartHour).format("YYYY-MM-DD HH:mm:ss"),
          dataFinalServico: moment(moment(targetData.value.datePicker[1]).format("YYYY-MM-DD") + " " + targetData.value.taskEndHour).format("YYYY-MM-DD HH:mm:ss"),
          codTecnico: targetData.value.tecnico,
          observacao: null
        }
      } else if(targetData.value.taskType == 10 || targetData.value.taskType == 11){
        postData = {
          cnpjConcessionaria: concessionariaInfo.value.cnpj,
          codConcessionaria: concessionariaInfo.value.codConcessionaria,
          codTipoServico: targetData.value.taskType,
          dataInicioServico: moment(targetData.value.datePicker[0]).format("YYYY-MM-DD HH:mm:ss"),
          dataFinalServico: moment(targetData.value.datePicker[1]).endOf("day").format("YYYY-MM-DD HH:mm:ss"),
          codTecnico: targetData.value.tecnico,
          observacao: null
        }
      } else if(targetData.value.taskType == 12){
        const dataSelecionadaTratada = moment(targetData.value.taskDate).format("YYYY-MM-DD");
        postData = {
          cnpjConcessionaria: concessionariaInfo.value.cnpj,
          codConcessionaria: concessionariaInfo.value.codConcessionaria,
          codTipoServico: targetData.value.taskType,
          dataInicioServico: moment(dataSelecionadaTratada + " " + targetData.value.taskStartHour).format("YYYY-MM-DD HH:mm:ss"),
          dataFinalServico: moment(dataSelecionadaTratada + " " + targetData.value.taskEndHour).format("YYYY-MM-DD HH:mm:ss"),
          codTecnico: targetData.value.tecnico,
          observacao: targetData.value.detalhes
        }
      };

      return postData;
    }

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          const postData = prepareForm();
          addNewTask(postData);
        }
      });
    };

    return {
      tecnicoOptions,
      formRef,
      newTargetModalRef,
      loading,
      targetData,
      showContent,
      rules,
      submit,
      disabledHours,
      initialTaskEndHour,
      initialHourEntrega,
      verificaHorarioInicial,
      verificaHorarioInicialRecepcao,
      tipoOptions,
      ptBr,
      closeModal,
      horarios
    };
  },
});
